import React, { useState } from 'react';
import ArrowRight from '../../../assets/images/account-page/arrowRight.svg';
import ArrowDown from '../../../assets/icons/arrow-down-icon.svg';
import ArrowUp from '../../../assets/icons/arrow-up-icon.svg';
import PlaneSubscription from '../../../assets/icons/plane-subscription-icon.svg';
import { useIntl } from 'react-intl';
import messages from './messages';
import config from '../../../config';
import moment from 'moment';

const locale = {
  en: 'en-gb',
  nl: 'nl',
  es: 'es',
  pl: 'pl',
  be: 'fr',
  fr: 'fr',
  it: 'it',
  de: 'de',
  da: 'da',
};

/* tslint:disable no-var-requires */
require(`moment/locale/${locale[config?.locale] || 'en-gb'}`);

const OverviewFlightTrips = (props: any) => {
  const data = props;
  const [open, setOpen] = useState(true);
  const { formatMessage } = useIntl();

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div key={data?.data?.id} className="flight-subscription-container">
        <span className="flight-date" style={{ opacity: '0.5' }}>
          {moment(data?.data?.subscriptionFlights?.[0]?.flight_date).format(
            'Do MMMM YYYY'
          )}
        </span>
        <div className="flight-box-container overflow-hidden">
          <div className="box-header flex">
            <div className="box-header-info flex">
              <div className="airports">
                <span className="iata-info">
                  {data?.data?.subscriptionFlights?.[0]?.departureAirport?.iata}
                </span>
                <img src={ArrowRight} alt="" width={15} height={15} />
                <span className="iata-info">
                  {data?.data?.subscriptionFlights?.[0]?.arrivalAirport?.iata}
                </span>
              </div>
              <div className="vertical-line" />
              <span>{data?.data?.subscriptionFlights?.[0]?.airline?.name}</span>
              <div className="vertical-line" />
              <span>{data?.data?.subscriptionFlights?.[0]?.flight_code}</span>
            </div>
            <div className="box-header-arrow">
              <img
                src={open ? ArrowUp : ArrowDown}
                alt=""
                style={{ cursor: 'pointer' }}
                onClick={handleOpen}
              />
            </div>
          </div>
          {open
            ? data?.data?.subscriptionFlights.map((item: any) => (
                <>
                  <div className="box-content flex">
                    <div className="content-container flex">
                      <div className="flight-info flex">
                        <span className="iata-info">
                          ({item?.departureAirport?.iata})
                        </span>
                        <hr style={{ marginLeft: '15px' }} />
                        <img
                          src={PlaneSubscription}
                          alt=""
                          width={24}
                          height={24}
                        />
                        <hr style={{ marginRight: '15px' }} />
                        <span className="iata-info">
                          ({item?.arrivalAirport?.iata})
                        </span>
                      </div>
                      <div>
                        <span className="flight-nr" style={{ opacity: '50%' }}>
                          {formatMessage(messages.subscriptionFlightNr)}{' '}
                          {item?.flight_code}
                        </span>
                        <span
                          className="flight-date"
                          style={{ marginLeft: '12px', opacity: '50%' }}
                        >
                          {formatMessage(messages.subscriptionFlightDate)}{' '}
                          {moment(item?.flight_date).format('L')}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default OverviewFlightTrips;
