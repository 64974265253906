import React, { Fragment, useState } from 'react';
import AirportSelect, {
  airportSelectMessages,
} from '../../../../components/fields/AirportSelect';
import DepartureIcon from '../../../../assets/icons/departure-icon.inline.svg';
import ArrivalIcon from '../../../../assets/icons/arrival-icon.inline.svg';
import StopoverIcon from '../../../../assets/icons/stop-icon.inline.svg';
import { Form, Formik, FieldArray } from 'formik';
import AddRoundIcon from '../../../../assets/icons/add-round-white-icon.svg';
import MinusIcon from '../../../../assets/images/account-page/minus.png';
import PlaneSubscription from '../../../../assets/icons/plane-subscription-icon.svg';
import EditIcon from '../../../../assets/icons/edit-icon.svg';
import Stopover from '../../../../assets/icons/stopover-icon.svg';
import { useGlobalState } from '../../../../services/GlobalStore/GlobalStore';
import { useIntl } from 'react-intl';
import messages from '../messages';

const StepperRoute = (props: any) => {
  const {
    activeStep,
    setActiveStep,
    setArrival,
    setDeparture,
    setStopovers,
    stopovers,
    departure,
    arrival,
    setHasAirportsData,
  } = props;
  const [state] = useGlobalState();
  const { formatMessage } = useIntl();

  const handleStoppoversAirports = (values: any) => {
    setDeparture(values?.departure_airport_id);
    setArrival(values?.arrival_airport_id);
    setStopovers(values?.stopovers);
    setHasAirportsData(true);
    setActiveStep(1);
  };
  const getAirport = (item: any) => {
    const allAirports = Object.values(state?.airports);

    if (Array.isArray(item)) {
      const airportArray: any[] = [];
      item?.forEach(itemAirport => {
        allAirports?.forEach((airport: any) => {
          if (itemAirport === airport?.id) {
            airportArray.push(airport?.name);
          }
        });
      });
      return airportArray.join(' ,');
    } else {
      let selectedAirport = '';
      allAirports.forEach(airport => {
        if (airport?.id === item) {
          selectedAirport = airport?.iata || airport?.name;
        }
      });
      return selectedAirport;
    }
  };

  return (
    <div className="stepper-route-container">
      {activeStep === 0 ? (
        <Formik
          initialValues={{ stopovers: [null] }}
          // onSubmit={handleSubmit}
          isInitialValid={false}
          onSubmit={handleStoppoversAirports}
          // validationSchema={validationSchema}
          initialErrors={{}}
        >
          {({ values, setValues }) => {
            return (
              <>
                <Form>
                  <AirportSelect
                    name="departure_airport_id"
                    label={'Departure airport'}
                    placeholder={
                      airportSelectMessages.placeholderDepartureAirport
                    }
                    icon={<DepartureIcon />}
                    autoComplete="off"
                    value="airport"
                  />
                  <FieldArray
                    name={'stopovers'}
                    // eslint-disable-next-line react/jsx-no-bind
                    render={({ remove, push }) => (
                      <Fragment>
                        {values?.stopovers?.length > 0
                          ? values?.stopovers.map((stop, index) => (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  alignItems: 'end',
                                  margin: '10px 0px',
                                }}
                              >
                                <AirportSelect
                                  name={`stopovers.${index}`}
                                  label={messages.finalDestinationLabel}
                                  placeholder={
                                    airportSelectMessages.placeholderStopoverAirport
                                  }
                                  icon={<StopoverIcon />}
                                  autoComplete="off"
                                />
                                {index === 0 ? (
                                  <button
                                    style={{}}
                                    className="add-airport-button"
                                    // eslint-disable-next-line
                                    onClick={() => push(null)}
                                  >
                                    {' '}
                                    <img
                                      src={AddRoundIcon}
                                      height={24}
                                      width={24}
                                      alt=""
                                    />{' '}
                                  </button>
                                ) : (
                                  <button
                                    className="add-airport-button"
                                    // eslint-disable-next-line
                                    onClick={() => remove(index)}
                                  >
                                    <img
                                      src={MinusIcon}
                                      width={24}
                                      height={24}
                                      alt=""
                                    />
                                  </button>
                                )}
                              </div>
                            ))
                          : null}
                      </Fragment>
                    )}
                  />
                  <AirportSelect
                    name="arrival_airport_id"
                    label={messages.finalDestinationLabel}
                    placeholder={
                      airportSelectMessages.placeholderArrivalAirport
                    }
                    icon={<ArrivalIcon />}
                    autoComplete="off"
                  />
                  <button
                    className="button-coonection"
                    type="submit"
                    disabled={
                      !values?.departure_airport_id ||
                      !values?.arrival_airport_id
                    }
                  >
                    Save and continue
                  </button>
                </Form>
              </>
            );
          }}
        </Formik>
      ) : (
        <div className="airports-container-info">
          <div className="airports-info">
            <div className="airports">
              <span className="iata-info">{getAirport(departure)}</span>
              <img src={PlaneSubscription} alt="" width={24} height={24} />
              <hr style={{ marginLeft: '15px' }} />
              <img src={Stopover} alt="" width={24} height={24} />
              <hr style={{ marginRight: '15px' }} />
              <img src={PlaneSubscription} alt="" width={24} height={24} />
              <span className="iata-info">{getAirport(arrival)}</span>
            </div>
            <div className="stoppover">
              <span>
                {formatMessage(messages.stoppoverLabel)} {getAirport(stopovers)}
              </span>
            </div>
          </div>
          <div className="edit-airports">
            <img
              src={EditIcon}
              width={24}
              height={24}
              style={{ cursor: 'pointer' }}
              onClick={() => setActiveStep(0)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StepperRoute;
