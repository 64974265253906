import React from 'react';

// Components
import PageTransition from '../components/PageTransition';
import routes from '../messages/routes';
import Cancelled from '../views/BankDetails/Cancelled';
import NotFoundPage from '../pages/404';

const IndexPage = () => (
  <PageTransition basepath={'/'} showStepper={false} hideMenu>
    <Cancelled path={routes.cancelled} />
    <NotFoundPage default />
  </PageTransition>
);

export default IndexPage;
