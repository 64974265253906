import React from 'react';
import Card from '../../../components/Card';
import BuySubscriptionInfo from './BuySubscriptionInfo';
import PersonalDataIcon from '../../../assets/icons/protected-data.svg';
import Banner from './Banner';
import PremiumFaq from './PremiumFaq';
import messages from './messages';
import { useIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import {
  useGlobalState,
  useJsonApi,
} from '../../../services/GlobalStore/GlobalStore';
import { jsonApiAxios } from '../../../services/api';
import useAuthenticate from '../../../hooks/useAuthenticate';
import config from '../../../config.json';

const faq = [
  {
    id: 1,
    question: 'Wie of wat is Yource?',
    answer:
      'Yource is de naam van ons moederbedrijf, en daarmee onze officiële bedrijfsnaam. De correspondentie met luchtvaartmaatschappijen gaat daarom ook vanuit deze naam. Onder Yource vallen naast www.your-ce.com ook websites als www.vlucht-vertraagd.nl en www.flight-delayed.co.uk. Het maakt daarom in principe niet uit op welke van deze websites je jouw claim indient, aangezien het dezelfde service betreft.',
  },
];

const BuySubscription = () => {
  const [state] = useGlobalState();
  const userId = localStorage.getItem('customerId');
  const { formatMessage } = useIntl();
  const { token } = useAuthenticate();
  const headers = token ? { Authorization: `Bearer ${token}` } : {};

  const { data: faqData, loading: faqLoading } = useJsonApi(
    `${config?.portalUrl}/api/frequentlyAskedQuestions`,
    {
      cachePolicy: 'no-cache',
      headers: { Authorization: `Bearer ${token}` },
    },
    []
  );

  const handlePremium = async () => {
    const stripeUrl = await jsonApiAxios.post(
      '/subscriptionCheckout',
      {},
      {
        schema: {},
        type: 'subscriptionCheckout',
        headers,
      }
    );

    if (stripeUrl?.data?.data?.attributes?.url) {
      window.location.href = stripeUrl?.data?.data?.attributes?.url;
    }
  };

  return (
    <div
      className="flex flex-col no-subscription-container"
      style={{ gap: '28px', margin: '28px auto' }}
    >
      <Banner name={state[`/accounts/me-${userId}`]?.firstname} />
      <Card style={cardStyles} onlyDesktop={true}>
        <span style={h1Styles}>{formatMessage(messages.howWorksTitle)}</span>
        <div className="flex how-works-container">
          <div className="box-buy" style={boxStyles}>
            <span style={subHeader}>
              1. {formatMessage(messages.premiumSubscribeTitle)}
            </span>
            <span style={textStyles}>
              {formatMessage(messages.howWorksPremium)}
            </span>
          </div>
          <div className="box-buy" style={boxStyles}>
            <span style={subHeader}>
              2. {formatMessage(messages.bookFlightTitle)}
            </span>
            <span style={textStyles}>
              {formatMessage(messages.bookFlightContent)}
            </span>
          </div>
          <div className="box-buy" style={boxStyles}>
            <span style={subHeader}>
              3. {formatMessage(messages.compensationTitle)}
            </span>
            <span style={textStyles}>
              {formatMessage(messages.compensationContent)}
            </span>
          </div>
        </div>
      </Card>
      <Card
        style={{ ...cardStyles, ...{ marginTop: isMobile ? '0px' : '28px' } }}
        onlyDesktop={true}
      >
        <div
          className="buy-subscription-content flex flex-col"
          style={contentBorderStyles}
        >
          <div className="header-menu-component flex">
            <span className="title">
              {formatMessage(messages.buyInfoTitle)}
            </span>
            <span className="sub-title">
              {formatMessage(messages.buyInfoSubtitle)}
            </span>
          </div>
          <div className="content-menu-component flex flex-col">
            <BuySubscriptionInfo />
            <div>
              <button style={buttonStyles} onClick={handlePremium}>
                {formatMessage(messages.becomePremiumButton)}
              </button>
            </div>
            <div className="flex" style={{ gap: '12px' }}>
              <img src={PersonalDataIcon} alt="Personal Data" />
              <span>{formatMessage(messages.bannerFooter)}</span>
            </div>
          </div>
        </div>
      </Card>
      {!faqLoading && <PremiumFaq faq={faqData || faq} />}
    </div>
  );
};

export default BuySubscription;

const cardStyles = {
  borderRadius: '12px',
  boxShadow: isMobile ? '' : '0px 4px 8px 0px #D8D8DA',
  padding: '48px',
  display: 'flex',
  gap: '24px',
  flexDirection: 'column',
  alignItems: 'center',
};

const h1Styles = {
  fontSize: '24px',
  fontWeight: '700',
  lineHeight: '25.92px',
  color: '#000000',
};

const boxStyles = {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  padding: '24px 32px',
  gap: '12px',
  boxShadow: '8.26px 0px 10.51px 0px #9D9D9D40',
  width: '247px',
  borderRadius: '12px',
  border: '1px solid #D4D4D4',
};

const subHeader = {
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '17.28px',
  color: '#000000',
};

const textStyles = {
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '17.64px',
  color: '#000000',
};

const contentBorderStyles = {
  border: '1px solid #D4D4D4',
  borderRadius: '12px',
};

const buttonStyles = {
  borderRadius: '6px',
  padding: '12px 22px',
  backgroundColor: '#F36B12',
  color: '#FFFFFF',
};
