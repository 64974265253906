import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { translateRoute } from '../../../utils/routes';
import routes from '../../../messages/routes';
import SideMenuItem, { MenuItem } from './SideMenuItem';
import { isMobile } from 'react-device-detect';

const SideMenu = ({ items }: { items: MenuItem[] }) => {
  const { state, pathname } = useLocation();
  const loginRoute =
    '/' +
    translateRoute(routes.account) +
    '/' +
    translateRoute(routes.myPremium);
  const isSubscription = pathname.includes(loginRoute);
  const className = isSubscription
    ? 'lg:mr-10 border-gray-700'
    : 'lg:mr-10 border-gray-700 side-menu';

  return (
    <aside className={className}>
      <div
        className="flex lg:flex-col align-center menu-sidebar-contianer"
        style={isMobile ? mobileAsideStyles : asideStyles}
      >
        {items.map((item, index) => {
          return item.hidden ? null : (
            <SideMenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              icon={item.icon}
              title={item.title}
              state={state}
              to={item.to}
              basepath={item.basepath}
            />
          );
        })}
      </div>
    </aside>
  );
};

SideMenu.propTypes = {
  items: PropTypes.array.isRequired,
};

export default SideMenu;

const asideStyles = {
  // backgroundColor: 'white',
  borderRadius: '12px',
  padding: '18px',
  // width: '213px',
  boxShadow: '0px 4px 8px 0px #D8D8DA',
  gap: '16px',
  backgroundColor: 'white',
  marginRight: '20px',
};

const mobileAsideStyles = {
  padding: '18px',
  gap: '16px',
  marginRight: '20px',
};
