import React, { Children } from 'react';
import Card from '../../../components/Card';
import AddIcon from '../../../assets/icons/plus-white-icon.inline.svg';
import { useIntl } from 'react-intl';
import messages from './messages';
import OverviewFlightTrips from './OverviewFlightTrips';

const TripOverview = (props: any) => {
  const { setAddTrip, data } = props;
  const { formatMessage } = useIntl();

  const handleAddTrip = () => {
    setAddTrip(true);
  };

  return (
    <Card style={cardStyles}>
      <div className="trip-overview-container">
        <div className="trip-overview-header">
          <span className="header">
            {formatMessage(messages.tripOverviewTitle)}
          </span>
        </div>
        {data?.length ? (
          <>
            {data[0].subscriptionTrips.map((trip: any) => (
              <OverviewFlightTrips data={trip} key={trip?.id} />
            ))}
            <div className="trip-overview-footer tip-footer-button-flights">
              <button
                className="trip-overview-footer-button flex"
                onClick={handleAddTrip}
              >
                <AddIcon
                  style={{ width: '20px', height: '20px', color: 'white' }}
                />
                <span>{formatMessage(messages.noTripsLabel)}</span>
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="trip-overview-content">
              <div className="trip-overview-content-header">
                <span>No trips added yet</span>
              </div>
              <div className="trip-overview-content-subheader">
                <span className="trip-overview-content-subheader-first">
                  {formatMessage(messages.uploadSubHeader)}
                </span>
                <span className="trip-overview-content-subheader-second">
                  {formatMessage(messages.uploadSubText)}
                </span>
              </div>
            </div>
            <div className="trip-overview-footer">
              <button
                className="trip-overview-footer-button flex"
                onClick={handleAddTrip}
              >
                <AddIcon
                  style={{ width: '20px', height: '20px', color: 'white' }}
                />
                <span>{formatMessage(messages.noTripsLabel)}</span>
              </button>
            </div>
          </>
        )}
      </div>
    </Card>
  );
};

export default TripOverview;

const cardStyles = {
  borderRadius: '12px',
  boxShadow: '0px 4px 8px 0px #D8D8DA',
};
