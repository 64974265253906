import overviewIcon from '../../../assets/icons/overview-icon.inline.svg';
import subscriptionIcon from '../../../assets/icons/subscription-documents-icon.inline.svg';
// import contactIcon from '../../../assets/icons/help-subscription-icon.inline.svg';
import contactIcon from '../../../assets/icons/contact-premium-icon.inline.svg';
import messages from '../messages';
import routes from '../../../messages/routes';

const subscriptionSideMenuItems = [
  {
    to: routes.myPremium,
    icon: overviewIcon,
    basepath: routes.account,
    title: messages.subscriptionOverview,
  },
  {
    to: routes.mySubscription,
    icon: subscriptionIcon,
    basepath: routes.account,
    title: messages.subscriptionSideMenuTitle,
  },
  {
    to: routes.helpCenter,
    icon: contactIcon,
    basepath: routes.account,
    title: messages.subscriptionContactSideMenuTitle,
  },
];

export default subscriptionSideMenuItems;
