import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import Radio from '../../../../components/fields/Radio';
import messages from '../../../../views/messages/searchFlightForm';
import DepartureIcon from '../../../../assets/icons/departure-icon.inline.svg';
import ArrivalIcon from '../../../../assets/icons/arrival-icon.inline.svg';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import AirportSelect, {
  airportSelectMessages,
} from '../../../../components/fields/AirportSelect';
import AirlineSelect from '../../../../components/fields/AirlineSelect';
import Input from '../../../../components/fields/Input';
import { jsonApiAxios } from '../../../../services/api';
import useAuthenticate from '../../../../hooks/useAuthenticate';
import DatePicker from '../../../../components/fields/DatePicker';
import EnterConnectingFlights from './EnterConnectingFlights';
import FlightComponent from '../FlightComponent';
import { useTranslatedNavigate } from '../../../../utils/routes';
import routes from '../../../../../src/messages/routes';
import Datetime from 'react-datetime';

const dossierItemSchema = {
  attributes: ['file', 'flights'],
  file: {
    ref: 'id',
  },
};

const subscriptionFlightsSchema = {
  attributes: [
    'subscriptionTrip',
    'flight_code',
    'flight_date',
    'departureAirport',
    'airline',
    'arrivalAirport',
  ],
  subscriptionTrip: {
    ref: 'id',
  },
  departureAirport: {
    ref: 'id',
  },
  airline: {
    ref: 'id',
  },
  arrivalAirport: {
    ref: 'id',
  },
};

const tripSchema = {
  attributes: ['flights_status'],
};

const FindSingleFlight = (props: any) => {
  const dayAfterTomorrow = Datetime.moment()
    .add(2, 'days')
    .startOf('day');
  const valid = current => {
    return current.isAfter(dayAfterTomorrow);
  };

  const {
    isReturnFlight,
    setIsReturnFlight,
    setIsFlightPage,
    departureFlight,
    setDepartureFlight,
    setReturnFlight,
  } = props;
  const [connectionType, setConnectionType] = useState('direct');
  const [hasReturnFlight, setHasReturnFlight] = useState(true);
  const [initialValues, setInitialValue] = useState({});
  const [showFlightComponent, setShowFlightComponent] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [flights, setFlights] = useState([]);
  const [flightData, setFlightsData] = useState<any[]>([]);
  const [flightsToSave, setFlightsToSave] = useState<any[]>([]);
  const { token } = useAuthenticate();
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  const translatedNavigate = useTranslatedNavigate();

  const { formatMessage } = useIntl();

  const changeConnectionType = (value: string) => {
    setConnectionType(value);
  };

  const handleSubmit = (values: any, { resetForm }) => {
    if (!hasReturnFlight) {
      setDepartureFlight(values);
      setIsFlightPage(true);
    } else {
      if (isEmpty(departureFlight)) {
        setInitialValue({
          departure_airport: values?.arrival_airport,
          arrival_airport: values?.departure_airport,
          flight_code: '',
          airline: values?.airline,
          connectionType: values?.connectionType,
        });

        setDepartureFlight(values);
        setIsReturnFlight(true);
        resetForm({});
      } else {
        setReturnFlight(values);
        setIsFlightPage(true);
      }
    }
  };

  const handleSaveConnectingFlight = async () => {
    const trip = await jsonApiAxios.post(
      '/subscriptionTrips',
      {},
      {
        schema: dossierItemSchema,
        type: 'subscriptionTrips',
        headers,
      }
    );

    if (trip?.id) {
      const flightValues: any[] = [];
      flightData[0]?.flights?.forEach(item => {
        flightsToSave?.forEach(itemSave => {
          if (itemSave === item?.flight_date) {
            item.subscriptionTrip = { id: trip?.id };
            flightValues.push(item);
          }
        });
      });

      for (const values of flightValues) {
        await attachFiles(values);
      }

      updateFlightStatus(trip?.id);
    }
  };

  const attachFiles = (values: any) => {
    return jsonApiAxios.post('/subscriptionFlights', values, {
      schema: subscriptionFlightsSchema,
      type: 'subscriptionFlights',
      headers,
    });
  };

  const updateFlightStatus = (tripId: string) => {
    jsonApiAxios
      .patch(
        `/subscriptionTrips/${tripId}`,
        {
          flights_status: 'Queued',
        },
        {
          schema: tripSchema,
          type: 'subscriptionTrips',
          headers,
        }
      )
      .then(res => {
        translatedNavigate(routes.myPremium);
      });
  };

  const checkValues = values => {
    if (
      !values?.connectionType ||
      !values?.departure_airport ||
      !values?.arrival_airport ||
      !values?.airline ||
      !values?.departure_date ||
      !values?.flight_code
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, setValues }) => (
          <Form>
            <Radio
              className="sm:max-w-none max-w-none"
              name="connectionType"
              // eslint-disable-next-line
              onChangeCallback={changeConnectionType}
              options={[
                {
                  label: messages.connectionTypeLabelDirect,
                  value: 'direct',
                },
                {
                  label: messages.connectionTypeLabelConnecting,
                  value: 'connecting',
                },
              ]}
            />
            {connectionType === 'direct' ? (
              <>
                <div className="airport-container flex">
                  <AirportSelect
                    name="departure_airport"
                    label={airportSelectMessages.labelDepartureAirport}
                    placeholder={
                      airportSelectMessages.placeholderDepartureAirport
                    }
                    icon={<DepartureIcon />}
                    autoComplete="off"
                    defaultOptions={false}
                    prefillWithFirstOption={false}
                    noClass={true}
                    className="aiport-subscription"
                  />
                  <AirportSelect
                    name="arrival_airport"
                    label={airportSelectMessages.labelArrivalAirport}
                    placeholder={
                      airportSelectMessages.placeholderArrivalAirport
                    }
                    icon={<ArrivalIcon />}
                    autoComplete="off"
                    defaultOptions={false}
                    prefillWithFirstOption={false}
                    noClass={true}
                    className="aiport-subscription"
                  />
                </div>
                <div
                  className="airport-container flex"
                  style={{ marginTop: '24px' }}
                >
                  <AirlineSelect
                    name="airline"
                    autoComplete="off"
                    noClass={true}
                    className="aiport-subscription"
                  />
                  <div className="row-container flex" style={{ gap: '24px' }}>
                    <DatePicker
                      name="departure_date"
                      label={messages.flightDateLabel}
                      autoComplete="off"
                      noClass={true}
                      className="subscription-width-input"
                      isValidDate={valid}
                    />
                    <Input
                      name="flight_code"
                      label={messages.flightNumberLabel}
                      placeholder={messages.flightCodePlaceholder}
                      noClass={true}
                      className="subscription-width-input"
                    />
                  </div>
                </div>
                {!isReturnFlight && (
                  <div style={{ marginTop: '24px' }}>
                    <input
                      type="checkbox"
                      checked={hasReturnFlight}
                      onChange={() => setHasReturnFlight(!hasReturnFlight)}
                    />
                    <span
                      className="return-flight-label"
                      style={{ marginLeft: '10px' }}
                    >
                      {formatMessage(messages.returnFlightType)}
                    </span>
                  </div>
                )}
                <div style={{ marginTop: '24px' }}>
                  <button
                    className="button-manual-entry"
                    type="submit"
                    disabled={checkValues(values)}
                  >
                    {formatMessage(messages.continueManualFlight)}
                  </button>
                </div>
              </>
            ) : connectionType === 'connecting' && !showFlightComponent ? (
              <>
                <EnterConnectingFlights
                  setFlights={setFlights}
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  setFlightsData={setFlightsData}
                  flightData={flightData}
                />
                <div style={{ marginTop: '24px' }}>
                  <button
                    className="button-manual-entry"
                    onClick={() => setShowFlightComponent(true)}
                  >
                    {formatMessage(messages.continueManualFlight)}
                  </button>
                </div>
              </>
            ) : (
              <>
                <FlightComponent
                  data={flightData}
                  addFlight={true}
                  setFlightsToSave={setFlightsToSave}
                />
                <div style={{ marginTop: '24px' }}>
                  <button
                    className="button-manual-entry"
                    onClick={handleSaveConnectingFlight}
                  >
                    {formatMessage(messages.continueManualFlight)}
                  </button>
                </div>
              </>
            )}
            {/* <AirportFields setValues={setValues} values={values} /> */}
            {/* {connectionType === 'connecting' && (
                <Card onlyMobile={true}>
                <StepNavigation
                dataLayerPushValueOnSubmit={'connectionType'}
                submitButtonMessage={formatMessage(messages.continue)}
                step={steps.FlightCompensation}
                portalId="delegateSubmit"
                />
                </Card>
                )} */}
            {/* <FormikPersist localStorageKey="flight-type-form" /> */}
          </Form>
        )}
      </Formik>
      {/* {(!connectionType || connectionType === 'direct') ? (
            <FlightSearch
              step={steps.FlightCompensation}
              flightType={'booked'}
              navigateNext={handleSubmit}
              fixedAirports={[]}
              showAirportFields={false}
              connectionHasSelected={false}
              isFirstPage={false}
              initialValues={{}}
            />
        ): (
          <EnterConnectingFlights />
        )} */}
      <div id="delegateSubmit" />
    </>
  );
};

export default FindSingleFlight;
