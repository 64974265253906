import React, { useState } from 'react';
import { useGlobalState } from '../../../../services/GlobalStore/GlobalStore';
import PlaneSubscription from '../../../../assets/icons/plane-subscription-icon.svg';
import { Form, Formik } from 'formik';
import DatePicker from '../../../../components/fields/DatePicker';
import Input from '../../../../components/fields/Input';
import AirlineSelect from '../../../../components/fields/AirlineSelect';
import EditIcon from '../../../../assets/icons/edit-icon.svg';
import FindStepperFlight from './FindStepperFlight';
import messages from '../messages';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import flightMessages from '../../../BankDetails/messages';
import Datetime from 'react-datetime';

const StepperInfo = (props: any) => {
  const dayAfterTomorrow = Datetime.moment()
    .add(2, 'days')
    .startOf('day');
  const valid = current => {
    return current.isAfter(dayAfterTomorrow);
  };

  const {
    departureFlight,
    arrivalFlight,
    setFlights,
    editIdx,
    setEditIdx,
    index,
    total,
    setActiveStep,
    setFlightsData,
    flightData,
  } = props;
  const [searchFlight, setSearchFlight] = useState({});
  const [state] = useGlobalState();
  const { formatMessage } = useIntl();
  const getAirport = (item: any) => {
    const allAirports = Object.values(state?.airports);

    if (Array.isArray(item)) {
      const airportArray: any[] = [];
      item?.forEach(itemAirport => {
        allAirports?.forEach((airport: any) => {
          if (itemAirport === airport?.id) {
            airportArray.push(airport?.name);
          }
        });
      });
      return airportArray.join(' ,');
    } else {
      let selectedAirport = '';
      allAirports.forEach(airport => {
        if (airport?.id === item) {
          selectedAirport = airport?.iata || airport?.name;
        }
      });
      return selectedAirport;
    }
  };

  const getAilrine = (item: any) => {
    const allAirlines = Object.values(state?.airlines);
    let selectedAirline = '';
    allAirlines.forEach(airline => {
      if (airline?.id === item) {
        selectedAirline = airline?.name;
      }
    });
    return selectedAirline;
  };

  const handleSaveFlight = (values: any) => {
    const flight = {
      airline: {
        id: values?.airline,
        name: getAilrine(values?.airline),
      },
      flight_code: values?.flight_code,
      flight_date: values?.flight_date,
      departureAirport: {
        id: departureFlight,
        iata: getAirport(departureFlight),
      },
      arrivalAirport: {
        id: arrivalFlight,
        iata: getAirport(arrivalFlight),
      },
    };
    setSearchFlight(flight);
    setFlights((prevState: any) => [...prevState, flight]);
    // setEditIdx(index + 1)
    if (total === index) {
      setActiveStep(2);
    }
  };

  return (
    <div className="stepper-info-container" style={{ marginTop: '10px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="airports">
          <span className="iata-info" style={{ marginRight: '10px' }}>
            {getAirport(departureFlight)}
          </span>
          <img src={PlaneSubscription} alt="" width={24} height={24} />
          <span className="iata-info" style={{ marginLeft: '10px' }}>
            {getAirport(arrivalFlight)}
          </span>
        </div>
        {index !== editIdx && (
          <div className="edit-airports">
            <img
              src={EditIcon}
              width={24}
              height={24}
              style={{ cursor: 'pointer' }}
              onClick={() => setEditIdx(index)}
            />
          </div>
        )}
      </div>
      {index === editIdx ? (
        <Formik
          enableReinitialize={true}
          initialValues={{}}
          onSubmit={handleSaveFlight}
        >
          {({ values, setValues }) => (
            <Form>
              <div className="connecting-flights-container-form flex">
                <DatePicker
                  name="flight_date"
                  label={messages.subscriptionFlightDate}
                  autoComplete="off"
                  noClass={true}
                  className="connecting-flights-date-inputs"
                  isValidDate={valid}
                />
                <Input
                  name="flight_code"
                  label={formatMessage(flightMessages.flightNumberLabel)}
                  placeholder={formatMessage(
                    flightMessages.flightCodePlaceholder
                  )}
                  noClass={true}
                  className="connecting-flights-inputs"
                />
              </div>
              <div style={{ width: '46%', marginTop: '10px' }}>
                <AirlineSelect
                  name="airline"
                  autoComplete="off"
                  noClass={true}
                  className="connecting-flights-inputs"
                />
              </div>
              {!isEmpty(searchFlight) && (
                <div>
                  <FindStepperFlight
                    flightValues={searchFlight}
                    setEditIdx={setEditIdx}
                    index={index}
                    setFlightsData={setFlightsData}
                    flightData={flightData}
                  />
                </div>
              )}
              <div style={{ marginTop: '15px' }}>
                <button
                  type="submit"
                  className="button-manual-entry flex"
                  disabled={
                    !values?.flight_date ||
                    !values?.flight_code ||
                    !values?.airline
                  }
                >
                  {formatMessage(messages.nextLabel)}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ) : null}
    </div>
  );
};

export default StepperInfo;
