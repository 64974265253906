import React, { useEffect } from 'react';
import { useJsonApi } from '../../../../services/GlobalStore/GlobalStore';
import useAuthenticate from '../../../../hooks/useAuthenticate';
import FlightComponent from '../FlightComponent';

const FlightsPage = (props: any) => {
  const { tripId, setFlightsToSave, setFlightsFound, errorReason = '' } = props;
  const { token } = useAuthenticate();

  const { data: flightsData } = useJsonApi(
    `/subscriptionTrips/${tripId}`,
    {
      cachePolicy: 'no-cache',
      queryParams: {
        include: [
          'flights.airline',
          'flights.departureAirport',
          'flights.arrivalAirport',
          'subscriptionBillingCycle',
        ].join(),
      },
      headers: { Authorization: `Bearer ${token}` },
    },
    []
  );

  useEffect(() => {
    if (flightsData?.length) {
      setFlightsFound(flightsData);
    }
  }, [flightsData?.length]);

  return (
    <div>
      {flightsData?.length ? (
        <FlightComponent
          data={flightsData}
          addFlight={true}
          setFlightsToSave={setFlightsToSave}
          errorReason={errorReason}
        />
      ) : null}
    </div>
  );
};

export default FlightsPage;
