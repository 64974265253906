import React from 'react';
import Card from '../../../components/Card';
import ArrowBack from '../../../assets/icons/arrow-back-icon.svg';
import SelectUploadOption from './SelectUploadOption';
import { useIntl } from 'react-intl';
import messages from './messages';

const SelectTripComponent = (props: any) => {
  const { setAddTrip, setUploadBooking } = props;
  const { formatMessage } = useIntl();

  const handleBack = () => {
    setAddTrip(false);
  };
  return (
    <Card style={cardStyles}>
      <div className="select-trip-container">
        <div className="select-trip-header">
          <div className="select-trip-back" onClick={handleBack}>
            <img
              src={ArrowBack}
              alt=""
              width={6}
              height={12}
              style={{ marginLeft: '4px' }}
            />
            <span>{formatMessage(messages.backButton)}</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="select-trip-main-header">
              {formatMessage(messages.optionUploadLabel)}
            </span>
            <span className="select-trip-main-subheader">
              {formatMessage(messages.manualEntrySubTitle)}
            </span>
          </div>
        </div>
        <div className="select-options-container flex" style={containerBorder}>
          <SelectUploadOption
            type="upload"
            setUploadBooking={setUploadBooking}
          />
          <SelectUploadOption
            type="manual"
            setUploadBooking={setUploadBooking}
          />
        </div>
      </div>
    </Card>
  );
};

export default SelectTripComponent;

const containerBorder = {
  borderRadius: '8px',
  border: '1px solid #dddddd',
};

const cardStyles = {
  borderRadius: '12px',
  boxShadow: '0px 4px 8px 0px #D8D8DA',
};
