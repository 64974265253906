import React, { useEffect } from 'react';

// Components
import NavLink from './NavLink';

// Utils
import routes from '../../messages/routes';
import { translateRoute } from '../../utils/routes';

// Backend services
import { useJsonApi } from '../../services/GlobalStore/GlobalStore';

// Messages
import messages from '../../messages/common';
import accountMessages from '../../views/Account/messages';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

// Icons
import Icon from '../../components/Icon';
import logoutIcon from '../../assets/icons/log-out-icon.svg';

import translations from '../../translations/main.json';

const mainMenuItems = (claimsLength: number, claimNumber: string) => {
  return [
    ...(claimsLength > 1
      ? [
          {
            to: routes.claimsOverview,
            basepath: routes.account,
            title: messages.myClaims,
          },
        ]
      : claimsLength === 1
      ? [
          {
            to:
              translations['cc4.seo.route.shareable-claim-page'] +
              `/${claimNumber}`,
            basepath: routes.account,
            title: messages.myClaim,
          },
        ]
      : []),
    {
      to: routes.myProfile,
      basepath: routes.account,
      title: accountMessages.myProfile,
    },
    {
      to: routes.helpCenter,
      basepath: routes.account,
      title: messages.support,
    },
    {
      to: '/',
      title: messages.newClaim,
    },
    // hide temporary the premium page
    // {
    //   to: routes.myPremium,
    //   basepath: routes.account,
    //   title: messages.myPremium,
    // },
  ];
};
/**
 * A header menu container component
 */
const HeaderMenu = (props: {
  menuOpen: boolean;
  logout: () => void;
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const { data: claims, get: getClaims } = useJsonApi(`/claims`, {
    cachePolicy: 'no-cache',
    queryParams: {
      include: [
        'customer',
        'placeOfJurisdiction',
        'customerFlights.airline',
        'customerFlights.departureAirport',
        'customerFlights.departureAirport.city',
        'customerFlights.arrivalAirport',
        'customerFlights.arrivalAirport.city',
      ].join(),
    },
  });

  useEffect(() => {
    if (!claims) getClaims();
  }, [claims, getClaims]);

  return (
    <div
      className={clsx(
        'w-full sm:flex-2 justify-between items-center flex-col sm:flex-row',
        {
          ['hidden sm:flex']: !props.menuOpen,
        }
      )}
    >
      <div className={'flex flex-col sm:w-full sm:justify-center sm:flex-row'}>
        {mainMenuItems(claims?.length, claims?.[0]?.claim_number).map(
          (item, index) => {
            const to = translateRoute(['/', item.to], {
              basepath: item.basepath,
            });
            return (
              <div
                style={{
                  whiteSpace: 'nowrap',
                }}
                className={
                  'flex w-full sm:w-auto items-start justify-start py-15 sm:border-none border-b border-gray-100'
                }
                // eslint-disable-next-line
                key={index}
              >
                <NavLink
                  // eslint-disable-next-line react/no-array-index-key
                  to={to}
                  className={
                    'text-root font-pt-sans font-bold text-black hover:text-primary-400 no-underline px-30'
                  }
                >
                  {formatMessage(item?.title)}
                </NavLink>
              </div>
            );
          }
        )}
      </div>
      <div
        className={
          'outline-none focus:outline-none flex w-full sm:w-auto items-center justify-start sm:justify-center px-30 py-15 sm:border-none border-b border-gray-100'
        }
      >
        <button
          type="button"
          className={
            'flex font-bold text-black sm:text-gray-400 sm:w-auto items-center justify-start sm:justify-center outline-none focus:outline-none'
          }
          onClick={props.logout}
        >
          {formatMessage(messages.logoutText)}
          <Icon className={'sm:flex hidden p-5'} src={logoutIcon} />
        </button>
      </div>
    </div>
  );
};

export default HeaderMenu;
