import { defineMessages } from 'react-intl';

export default defineMessages({
  uploadNowButton: {
    id: 'cc4.form.flight-itinerary.add-alternative-flight-button',
    defaultMessage: 'Add flight now',
  },
  bookedFlightsText: {
    id: 'cc4.form.flight-itinerary.booked-flights-text',
    defaultMessage: 'Booked flights',
  },
  alternativeFlightsText: {
    id: 'cc4.form.flight-itinerary.alternative-flights-text',
    defaultMessage: 'Alternative flights',
  },
  addConnectedFlights: {
    id: 'cc4.form.flight-itinerary.add-connecting-flight',
    defaultMessage: 'Add connecting flight',
  },
  addAlternativeFlight: {
    id: 'cc4.form.flight-itinerary.add-alternative-flight',
    defaultMessage: 'Add alternative flight',
  },
  missed_connection: {
    id: 'cc4.form.flight-itinerary.missed_connection',
    defaultMessage: 'Missed connection',
  },
  rebooked: {
    id: 'cc4.form.flight-itinerary.rebooked',
    defaultMessage: 'Rebooked',
  },
  denied: {
    id: 'cc4.form.flight-itinerary.denied',
    defaultMessage: 'Denied',
  },
  on_time: {
    id: 'cc4.form.flight-itinerary.on_time',
    defaultMessage: 'On time',
  },
  canceled: {
    id: 'cc4.form.flight-itinerary.canceled',
    defaultMessage: 'Canceled',
  },
  delayed: {
    id: 'cc4.form.flight-itinerary.delayed',
    defaultMessage: 'Delayed',
  },
  diverted: {
    id: 'cc4.form.flight-itinerary.diverted',
    defaultMessage: 'Diverted',
  },
  replacement: {
    id: 'cc4.form.flight-itinerary.replacement',
    defaultMessage: 'Replacement',
  },
});
