import overviewIcon from '../../../assets/icons/overview-icon.inline.svg';
import documentsIcon from '../../../assets/icons/documents-icon.inline.svg';
import calendarIcon from '../../../assets/icons/calendar-booking.inline.svg';
import passengerIcon from '../../../assets/icons/passengers-icon.inline.svg';
import flightIcon from '../../../assets/icons/flight-ticket-icon.inline.svg';
import routes from '../../../messages/routes';
import claimDetailsMessages from './claimDetailsMessages';

const claimDetailsSideMenuItems = (claimNumber: string) => [
  {
    to: [routes.account, routes.claim, claimNumber],
    icon: overviewIcon,
    title: claimDetailsMessages.claimSideMenuOverviewTitle,
  },
  {
    to: [routes.account, routes.claim, claimNumber, routes.bookingDetails],
    icon: calendarIcon,
    title: claimDetailsMessages.claimSideMenuBookingDetails,
  },
  {
    to: [routes.account, routes.claim, claimNumber, routes.claimDocuments],
    icon: documentsIcon,
    title: claimDetailsMessages.claimSideMenuDocumentsTitle,
  },
  {
    to: [routes.account, routes.claim, claimNumber, routes.flightItinerary],
    icon: flightIcon,
    title: claimDetailsMessages.flightItinerary,
  },
  {
    to: [routes.account, routes.claim, claimNumber, routes.passengersList],
    icon: passengerIcon,
    title: claimDetailsMessages.passengerTitle,
  },
];

export default claimDetailsSideMenuItems;
