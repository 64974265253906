import React from 'react';
import Stepper from './Stepper';
import messages from '../messages';

const EnterConnectingFlights = (props: any) => {
  const {
    setFlights,
    activeStep,
    setActiveStep,
    setFlightsData,
    flightData,
  } = props;

  const steps = [
    { label: messages.routeLabel },
    { label: messages.shareInfoLabel },
  ];

  return (
    <Stepper
      steps={steps}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      setFlights={setFlights}
      setFlightsData={setFlightsData}
      flightData={flightData}
    />
  );
};

export default EnterConnectingFlights;
