import React from 'react';
import messages from '../messages';
import { useIntl } from 'react-intl';
const Plan = () => {
  const { formatMessage } = useIntl();

  return (
    <div style={{ marginTop: '28px' }}>
      <span>{formatMessage(messages.planLabel)}</span>
      <div className="my-subscription-plan-container">
        <div className="my-subscription-plan-left">
          <span className="premium-header">
            {formatMessage(messages.premiumLabel)}
          </span>
          <span className="premium-subheader">
            {formatMessage(messages.priceLabel)}
          </span>
          <span className="premium-text">
            {formatMessage(messages.billedLabel)}
          </span>
          <span className="premium-text">
            <b>{formatMessage(messages.nextPayment)}</b>
          </span>
        </div>
        <div className="my-subscription-plan-right">
          <button className="button-primary-subscription">
            {formatMessage(messages.compensationLabel)}
          </button>
          <button className="button-secondary-subscription">
            {formatMessage(messages.autoRenewLabel)}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Plan;
