import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';

const FindStepperFlight = (props: any) => {
  const { flightValues, setEditIdx, index, setFlightsData, flightData } = props;

  useEffect(() => {
    if (!isEmpty(flightValues)) {
      const flightsArray: any = {
        flights: [],
      };

      flightsArray.flights.push({
        flight_code: flightValues?.flight_code,
        flight_date: flightValues?.flight_date,
        departureAirport: flightValues?.departureAirport,
        arrivalAirport: flightValues?.arrivalAirport,
        airline: flightValues?.airline,
      });
      if (!flightData?.length) {
        setFlightsData([flightsArray]);
      } else {
        setFlightsData((prevState: any) => {
          prevState[0].flights.push(...flightsArray.flights);
          return prevState;
        });
      }
      setEditIdx(index + 1);
    }
  }, [flightValues]);

  return null;
};

export default FindStepperFlight;
