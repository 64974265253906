import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { isMobile } from 'react-device-detect';

const Banner = (props: any) => {
  const { name } = props;
  const { formatMessage } = useIntl();

  return (
    <div
      className={`flex flex-col justify-center ${
        isMobile ? 'banner-no-img' : 'banner'
      }`}
      style={bannerStyles}
    >
      <div style={{ width: isMobile ? '100%' : '65%' }}>
        <span className="banner-title" style={headerStyles}>
          {formatMessage(messages.bannerTitle, { name })}
        </span>
        <br />
        <span style={subHeaderStyles}>
          {formatMessage(messages.bannerSubTitle)}
        </span>
      </div>
    </div>
  );
};

export default Banner;

const bannerStyles = {
  borderRadius: '4.98px',
  padding: '23.64px 29.86px',
  gap: '7.46px',
};

const headerStyles = {
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '25.92px',
  color: '#FFFFFF',
  width: '463px',
};

const subHeaderStyles = {
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '15.12px',
  color: '#FFFFFF',
  width: '410.26px',
};
