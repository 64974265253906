import React from 'react';
import { Link } from 'gatsby';
import SubTitleWithIcon from '../../PersonalInfo/SubTitleWithIcon';
import { translateRoute } from '../../../utils/routes';
import { MessageDescriptor, useIntl } from 'react-intl';
import clsx from 'clsx';

export interface MenuItem {
  hidden?: boolean;
  to: (string | MessageDescriptor)[];
  icon: JSX.Element;
  basepath?: MessageDescriptor;
  title: MessageDescriptor;
  state?: {};
}

/**
 * Due to link consisting of Icon and text side-menu-item class is added
 * to avoid double hover
 */
const linkClasses =
  'nav-link flex flex-shrink-0 border-b-2 items-center lg:border-none leading-loose no-underline text-default';

const isPartiallyActive = ({
  isPartiallyCurrent,
  className,
  isCurrent,
}: {
  isPartiallyCurrent: boolean;
  isCurrent: boolean;
  className: string;
}) => {
  // Need to be fixed in future for /account route
  return {
    className: clsx(
      { 'nav-link-active fill-primary': isCurrent && isPartiallyCurrent },
      linkClasses,
      className
    ),
  };
};

const SideMenuItem = (props: MenuItem) => {
  const { state, icon, basepath, title } = props;
  const to = translateRoute(props.to, {
    basepath,
  });
  const { formatMessage } = useIntl();

  return (
    <SubTitleWithIcon
      icon={icon}
      width={25}
      className={linkClasses}
      iconClassName="lg:block hidden mr-15"
      as={Link}
      elementProps={{
        activeClassName:
          'nav-link-active nav-link-active-side-menu fill-primary',
        to,
        state,
        isPartiallyActive,
      }}
    >
      {formatMessage(title)}
    </SubTitleWithIcon>
  );
};

export default SideMenuItem;
