import React, { useState } from 'react';
import AccountContainer from '../../../../components/AccountContainer';
import Card from '../../../../components/Card';
import ArrowBack from '../../../../assets/icons/arrow-back-icon.svg';
import FindSingleFlight from './FindSingleFlight';
import FlightResults from './FlightResults';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { useTranslatedNavigate } from '../../../../utils/routes';
import routes from '../../../../../src/messages/routes';

const EnterManualFlights = () => {
  const [isReturnFlight, setIsReturnFlight] = useState(false);
  const [isFlightPage, setIsFlightPage] = useState(false);
  const [departureFlight, setDepartureFlight] = useState({});
  const [returnFlight, setReturnFlight] = useState({});
  const { formatMessage } = useIntl();
  const translatedNavigate = useTranslatedNavigate();

  const handleBack = () => {
    if (isFlightPage || isReturnFlight) {
      setIsFlightPage(false);
      setIsReturnFlight(false);
    } else {
      translatedNavigate(routes.myPremium);
    }
  };

  return (
    <AccountContainer>
      <Card style={cardStyles}>
        <div className="select-trip-container">
          <div className="select-trip-header">
            <div className="select-trip-back" onClick={handleBack}>
              <img
                src={ArrowBack}
                alt=""
                width={6}
                height={12}
                style={{ marginLeft: '4px' }}
              />
              <span>{formatMessage(messages.backButton)}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {isReturnFlight ? (
                <span className="select-trip-main-header">
                  {formatMessage(messages.manualEntryTitle)}
                </span>
              ) : (
                <span className="select-trip-main-header">
                  {formatMessage(messages.manualEntryDepartureTitle)}
                </span>
              )}
              <span className="select-trip-main-subheader">
                {formatMessage(messages.manualEntrySubTitle)}
              </span>
            </div>
          </div>
          {isFlightPage ? (
            <FlightResults
              departureFlight={departureFlight}
              returnFlight={returnFlight}
            />
          ) : (
            <FindSingleFlight
              setIsReturnFlight={setIsReturnFlight}
              isReturnFlight={isReturnFlight}
              setIsFlightPage={setIsFlightPage}
              setReturnFlight={setReturnFlight}
              setDepartureFlight={setDepartureFlight}
              departureFlight={departureFlight}
            />
          )}
        </div>
      </Card>
    </AccountContainer>
  );
};

export default EnterManualFlights;

const cardStyles = {
  borderRadius: '12px',
  boxShadow: '0px 4px 8px 0px #D8D8DA',
};
