import React from 'react';
import swirlsImg from '../../assets/images/swirls.png';
import AlertTriangleIcon from '../../assets/icons/triangle-alert-icon.svg';
import StatusPaymentComponent from './StatusPaymentComponent';
import { jsonApiAxios } from '../../services/api';
import messages from '../Account/Subscription/messages';
import { useIntl } from 'react-intl';

if (typeof document !== 'undefined') {
  const body = document.getElementsByTagName('body')[0];
  body.style.backgroundImage = `url(${swirlsImg})`;
  body.style.backgroundPosition = 'center';
  body.style.backgroundRepeat = 'no-repeat';
  body.style.backgroundSize = 'cover';
}

const Cancelled = () => {
  const { formatMessage } = useIntl();
  const handleRetryPayment = async () => {
    const stripeUrl = await jsonApiAxios.post(
      '/subscriptionCheckout',
      {},
      {
        schema: {},
        type: 'subscriptionCheckout',
      }
    );
    if (stripeUrl?.data?.data?.attributes?.url) {
      window.location.href = stripeUrl?.data?.data?.attributes?.url;
    }
  };

  return (
    <StatusPaymentComponent
      title={formatMessage(messages.paymentCancelledStatus)}
      subTitle={formatMessage(messages.paymentCancelledStatusText)}
      buttonText={formatMessage(messages.paymentCancelledStatusButtonText)}
      icon={AlertTriangleIcon}
      onClick={handleRetryPayment}
    />
  );
};

export default Cancelled;
