import React, { useState, useEffect, useCallback } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useLocation, WindowLocation } from '@reach/router';
import Container from '../../components/Container';
import Main from '../../components/Main';
import Card from '../../components/Card';
import StepTitle from '../../components/StepTitle';
import messages from './messages';
import compensationMessages from '../CompensationCheck/messages';
import personalInfoMessages from '../PersonalInfo/messages';
import SubscriptionSideComponent from '../../components/SubscriptionSideComponent/SubscriptionSideComponent';
import subscriptionMessages from '../../views/BankDetails/messages';
import Button from '../../components/Button';
import SeparatorWithText from '../../components/SeparatorWithText';
import Icon from '../../components/Icon';
import Loading from '../../components/Loading';
import Input from '../../components/fields/Input';
import GoogleIcon from '../../assets/icons/google-icon.inline.svg';
import FacebookIcon from '../../assets/icons/facebook-icon.inline.svg';
import Alert from '../../components/Alert';
import clsx from 'clsx';
import Aside from '../../components/Aside';
import speedIcon from '../../assets/icons/speed-icon.svg';
import StepNavigation from '../../components/StepNavigation';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import {
  clearAllCookies,
  getResponseErrors,
  jsonApiAxios,
} from '../../services/api';
import '../../utils/yupIntlSetup';
import * as yup from 'yup';
import config from '../../config.json';
import translations from '../../translations/main.json';
import Seo from '../../components/seo';
import routes from '../../messages/routes';
import {
  steps,
  translateRoute,
  useTranslatedNavigate,
} from '../../utils/routes';
import {
  FLIGHT_COMPENSATION,
  PRODUCT_TYPE,
  TICKET_REFUND,
} from '../../services/appLocalStorage';
import useDataLayer from '../../hooks/useDataLayer';
import useAuthenticate, { LoginSource } from '../../hooks/useAuthenticate';
import { useJsonApi } from '../../services/GlobalStore/GlobalStore';
import { parse } from 'qs';

const validationSchema = yup.object().shape({
  password: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
});

export const ssoRedirectUrls = (shouldAuthenticate = false, queryParams) => {
  const congratulationsPath =
    translations['cc4.seo.route.congratulations-basepath'];
  const loginPath = translations['cc4.seo.route.login'];

  const success_redirect_to = shouldAuthenticate
    ? `//` +
      config.calculatorDomain +
      '/' +
      congratulationsPath +
      '?sso_success'
    : `//` +
      config.calculatorDomain +
      '/' +
      'sso-login' +
      '?sso_success' +
      (queryParams ? `&${queryParams}` : '');
  const failure_redirect_to =
    `//` +
    config.calculatorDomain +
    '/' +
    (shouldAuthenticate
      ? congratulationsPath + `?sso_error`
      : loginPath + `?sso_error`) +
    (queryParams ? `&${queryParams}` : '');

  return `failure_redirect_to=${encodeURIComponent(
    failure_redirect_to
  )}&success_redirect_to=${encodeURIComponent(success_redirect_to)}`;
};

type LoginLocationState = {
  source: LoginSource;
  isSubscription?: boolean;
};

type LoginFormValues = {
  email: string;
  password: string;
};

const Login = (): JSX.Element => {
  // if there is a token it means it's coming from the sharable claim/passenger page
  const { token = false, doLogin, isLoggedIn } = useAuthenticate();
  const { formatMessage } = useIntl();
  const translatedNavigate = useTranslatedNavigate();
  const [pushEvent] = useDataLayer();
  // Lower is suppressed as guys from reach router are still using older TypeScript
  // @ts-ignore
  const {
    search,
    state,
    pathname,
  }: WindowLocation<LoginLocationState> = useLocation();
  const [claimId, setClaimId] = useState<string>();

  const [localStorageClaimAmount] = useLocalStorageState('claimAmount');
  const [{ email: localStorageEmail } = { email: '' }] = useLocalStorageState<
    string
  >('compensationCheck');
  const [localStorageProductType] = useLocalStorageState(PRODUCT_TYPE);

  const [errors, setErrors] = useState<null | string[]>(null);
  const [should_authenticate, setShouldAuthenticate] = useLocalStorageState(
    'shouldAuthenticate'
  );

  const isCongratulationsPage = pathname.includes(
    translateRoute(routes[steps.CongratulationsBasepath])
  );

  const pushLoginEvent = useCallback(
    () =>
      pushEvent({
        eventLabel: should_authenticate || token ? 'log in' : 'sign up',
        eventAction: steps.Login,
      }),
    [pushEvent, should_authenticate, token]
  );

  useEffect(() => {
    const localStorageClaimId = localStorage?.getItem('yo:claimId');
    if (localStorageClaimId) {
      setClaimId(localStorageClaimId);
    }
  }, []);

  const { data: claim, get: getClaim, loading, isLoaded } = useJsonApi(
    `/claims/${claimId}`,
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
      queryParams: {
        include: [
          'passengers',
          'customer',
          'organizations',
          'currentState',
          'placeOfJurisdiction',
        ].join(),
      },
    }
  );

  useEffect(() => {
    if (claimId) getClaim();
  }, [claimId, getClaim]);

  useEffect(() => {
    if (state?.source !== LoginSource.Funnel && isLoggedIn)
      translatedNavigate(['/', routes.account]);
    // eslint-disable-next-line
  }, []);

  const claimObject = Array.isArray(claim) ? claim[0] : claim;
  const customer = claimObject?.customer;
  // should this be claim_amount_pp or compensation.amount or compensation_per_passenger.amount or total_value.amount???
  const claimAmount = claimObject?.claim_amount_pp || localStorageClaimAmount;
  const productType = claimObject?.product_type || localStorageProductType;
  const email = customer?.email || localStorageEmail;

  const openStripe = async () => {
    const stripeUrl = await jsonApiAxios.post(
      '/subscriptionCheckout',
      {},
      {
        schema: {},
        type: 'subscriptionCheckout',
      }
    );
    if (stripeUrl?.data?.data?.attributes?.url) {
      window.location.href = stripeUrl?.data?.data?.attributes?.url;
    }
  };

  async function login(
    values: LoginFormValues,
    { setSubmitting }: FormikHelpers<LoginFormValues>
  ) {
    /**
     * Correct login
     */
    try {
      const response = await jsonApiAxios.post('/login', values, {
        noSerialize: true,
      });
      if (response) {
        doLogin();
        if (state?.source === LoginSource.Funnel) {
          translatedNavigate(routes.congratulationsBasepath, {
            state: { should_authenticate: true, source: LoginSource.Funnel },
          });
        } else {
          doLogin();
          const { redirect } = parse(search, { ignoreQueryPrefix: true });
          if (redirect && !state?.isSubscription) {
            translatedNavigate(['/', routes.account]);
          } else if (state?.isSubscription) {
            openStripe();
          } else {
            translatedNavigate(['/', routes.account]);
          }
        }
      }
    } catch (error) {
      /**
       * Login failure
       */
      clearAllCookies();
      setSubmitting(false);
      if (error?.response) {
        setErrors(getResponseErrors(error));
      }
    }
  }

  async function handleSubmit(
    values: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>
  ) {
    setErrors(null);
    if (state?.source === LoginSource.Register) {
      try {
        const { status } = await jsonApiAxios.post('/register', values, {
          schema: {
            attributes: ['email', 'password'],
          },
          type: 'customers',
        });
        if (status === 200) {
          localStorage.clear();
          sessionStorage.clear();
          await login(values, formikHelpers);
        }
      } catch (error) {
        formikHelpers.setSubmitting(false);
        if (error?.response) {
          setErrors(getResponseErrors(error));
        }
      }
    } else {
      await login(values, formikHelpers);
    }
  }

  function navigateBack() {
    if (productType === TICKET_REFUND) {
      if (should_authenticate) {
        translatedNavigate(routes.checkCompensation);
      } else {
        translatedNavigate(routes.claimAmount, {
          basepath: routes.congratulationsBasepath,
        });
      }
    } else if (productType === FLIGHT_COMPENSATION) {
      if (should_authenticate) {
        translatedNavigate(routes.checkCompensation);
      } else {
        translatedNavigate(routes.bookingReference, {
          basepath: routes.congratulationsBasepath,
        });
      }
    }
  }

  const ssoAction = (provider: 'google' | 'facebook') => () => {
    const redirectTo = ssoRedirectUrls(should_authenticate, search);

    jsonApiAxios
      .post(
        `${
          isCongratulationsPage ||
          !state ||
          state?.source !== LoginSource.Funnel
            ? 'login'
            : 'register'
        }/${provider}?${redirectTo}`,
        {},
        {
          noSerialize: true,
        }
      )
      .then(({ data }: { data: { url: string } }) => {
        window.location.href = data.url;
      });
  };

  const initialValues = {
    email,
    password: '',
  };

  if (loading && !isLoaded) {
    return (
      <div className="flex justify-center mt-40">
        <Loading />
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ isValid, isSubmitting }) => (
        <Container>
          <Seo
            title={messages.seoTitle}
            description={messages.seoDescription}
          />
          {state?.isSubscription && <SubscriptionSideComponent />}
          <Main className="m-auto sm:max-w-740">
            <Form>
              <Card onlyDesktop={state?.isSubscription ? false : true}>
                {state?.isSubscription ? (
                  <div className="subscription-login-headers-container">
                    <span className="subscription-login-header">
                      {formatMessage(
                        subscriptionMessages.premiunSubscriptionTitle
                      )}
                    </span>
                    <span className="subscription-login-subheader">
                      {formatMessage(
                        subscriptionMessages.premiunSubscriptionSubTitle
                      )}
                    </span>
                  </div>
                ) : (
                  <StepTitle
                    className={clsx({
                      'text-center':
                        !should_authenticate ||
                        state?.source !== LoginSource.Funnel,
                    })}
                  >
                    {[LoginSource.Funnel, LoginSource.Register].includes(
                      state?.source
                    )
                      ? should_authenticate
                        ? productType === FLIGHT_COMPENSATION
                          ? formatMessage(
                              personalInfoMessages.personalInfoCompensationTitle,
                              {
                                amount: <span>€{claimAmount}</span>,
                              }
                            )
                          : formatMessage(
                              personalInfoMessages.personalInfoTicketRefundTitle
                            )
                        : formatMessage(messages.headerSignUp)
                      : formatMessage(messages.headerSignIn)}
                  </StepTitle>
                )}
                {search.includes('sso_error') ? (
                  <Alert level="danger" className="mb-40">
                    {formatMessage(messages.loginErrorMessage)}
                  </Alert>
                ) : (
                  should_authenticate &&
                  state?.source === LoginSource.Funnel && (
                    <Alert level="warning" className="mb-40">
                      {formatMessage(messages.warningAccountExists)}
                    </Alert>
                  )
                )}
                <Card onlyMobile={true}>
                  <SeparatorWithText
                    text={formatMessage(messages.continueWith)}
                    separatorClassName={'mb-25 sm:mb-30'}
                  />
                  <div className="flex sm:justify-center justify-between">
                    <button
                      type="button"
                      onClick={ssoAction('google')}
                      className="outline-none focus:outline-none mr-5 login-button border-google text-google hover:text-white hover:bg-google"
                    >
                      <GoogleIcon className="inline mr-15" />
                      <span>Google</span>
                    </button>
                    <button
                      type="button"
                      onClick={ssoAction('facebook')}
                      className="outline-none focus:outline-none ml-5 login-button border-facebook text-facebook hover:text-white hover:bg-facebook"
                    >
                      <FacebookIcon className="inline mr-15" />{' '}
                      <span>Facebook</span>
                    </button>
                  </div>
                  <SeparatorWithText
                    text={formatMessage(messages.orUsingYourEmail)}
                    separatorClassName={'my-25 sm:my-30 mt-40 sm:mt-45'}
                  />
                  <div className="fieldset sm:flex-row">
                    <Input
                      name="email"
                      label={formatMessage(
                        compensationMessages.yourEmailAddress
                      )}
                      placeholder={formatMessage(messages.placeholderEmail)}
                      autoComplete="email"
                      disabled={[
                        LoginSource.Funnel,
                        LoginSource.Register,
                      ].includes(state?.source)}
                    />
                    <Input
                      name="password"
                      type="password"
                      autoComplete="password"
                      label={formatMessage(messages.labelPassword)}
                      placeholder={
                        should_authenticate ||
                        token ||
                        state?.source === LoginSource.Funnel
                          ? messages.placeholderPassword
                          : messages.placeholderCreateNewPassword
                      }
                    />
                  </div>
                </Card>
                <div className="flex m-auto flex-col align-center items-center justify-center mx-8 sm:mx-0">
                  {errors && errors.length > 0
                    ? errors.map((error, index) => (
                        <Alert
                          // eslint-disable-next-line
                          key={`error-${index}`}
                          level="danger"
                          className="w-full my-20"
                        >
                          {error}
                        </Alert>
                      ))
                    : null}
                  {state?.isSubscription ? (
                    <button
                      onClick={pushLoginEvent}
                      className="confirm-button"
                      disabled={!isValid || isSubmitting}
                      style={{ marginTop: '25px' }}
                    >
                      {formatMessage(messages.loginButton)}
                    </button>
                  ) : (
                    <Button
                      theme="primary"
                      onClick={pushLoginEvent}
                      className="mt-25 sm:w-170"
                      disabled={!isValid || isSubmitting}
                      isSubmitting={isSubmitting}
                    >
                      {formatMessage(
                        state?.source === LoginSource.Register
                          ? messages.signUpButton
                          : messages.loginButton
                      )}
                    </Button>
                  )}
                  {should_authenticate ||
                  state?.source !== LoginSource.Funnel ? (
                    <p className="p-10 m-15">
                      {formatMessage(messages.resetPassword, {
                        // eslint-disable-next-line react/no-multi-comp
                        cta: (...chunks) => (
                          <a
                            href={translateRoute([
                              '/',
                              routes[steps.ForgotPassword],
                            ])}
                            // eslint-disable-next-line
                            onClick={() =>
                              pushEvent({ eventLabel: 'reset password' })
                            }
                            rel="noreferrer"
                          >
                            {chunks}
                          </a>
                        ),
                      })}
                    </p>
                  ) : (
                    <a
                      // eslint-disable-next-line
                      onClick={() => setShouldAuthenticate(true)}
                      className="p-10 m-15"
                    >
                      {formatMessage(messages.existingAccount)}
                    </a>
                  )}
                </div>
              </Card>
              {should_authenticate && state?.source === LoginSource.Funnel ? (
                <StepNavigation
                  onBackClick={navigateBack}
                  backButtonMessage={formatMessage(messages.signUpBack)}
                  dataLayerPushOnBack={true}
                  step={steps.Login}
                />
              ) : null}
            </Form>
          </Main>
          {should_authenticate && state?.source === LoginSource.Funnel && (
            <Aside>
              <Icon src={speedIcon} className="mb-20" />
              {formatMessage(personalInfoMessages.asideText)}
            </Aside>
          )}
        </Container>
      )}
    </Formik>
  );
};

export default Login;
