import { jsonApiAxios } from '../api';

export const updateBankDetailsSchema = {
  attributes: [
    'account_holder',
    'address',
    'postal_code',
    'city',
    'iban',
    'holder_address',
    'account_number',
    'donate_amount',
    'bic',
    'bank_code',
    'bank_name',
    'bank_city',
    'bank_address',
    'bank_postal_code',
    'bankCountry',
    'customer_wants_to_write_review',
    'create_subscription',
  ],
  bankCountry: {
    ref: 'id',
  },
};

export function updateBankDetails(id: any, values: any, config = {}) {
  const data: any = {
    schema: updateBankDetailsSchema,
    ...config,
    type: 'claimBankAccount',
  };
  return jsonApiAxios.patch(`/claimBankAccounts/${id}`, values, data);
}
