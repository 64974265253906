import { defineMessages } from 'react-intl';

export default defineMessages({
  bankDetailsTitle: {
    id: 'cc4.bank-details.title',
    defaultMessage: 'Update your bank details',
  },
  bankDetailsSubTitle: {
    id: 'cc4.bank-details.subtitle',
    defaultMessage:
      'Good news! We are almost ready to pay-out your compensation. We only need some additional bank details to finalise the transfer.',
  },
  claimInfoHeader: {
    id: 'cc4.bank-details.claim-info-header',
    defaultMessage: 'Your claim information',
  },
  donateCompensationTitle: {
    id: 'cc4.bank-details.donate-compensation-title',
    defaultMessage: 'Donate some of the compensation?',
  },
  donateCompensationSubTitle: {
    id: 'cc4.bank-details.donate-compensation-sub-title',
    defaultMessage: 'I would like to make a donation of:',
  },
  cardHolderTitle: {
    id: 'cc4.bank-details.card-holder-title',
    defaultMessage: 'Cardholder bank details',
  },
  cardHolderSubTitle: {
    id: 'cc4.bank-details.card-holder-sub-title',
    defaultMessage:
      'Make sure that the cardholder information matches with your card. This can sometimes be different as your personal information.',
  },
  countryBankLabel: {
    id: 'cc4.bank-details.country-bank-label',
    defaultMessage: 'Country of the bank',
  },
  holderLabel: {
    id: 'cc4.bank-details.holder-label',
    defaultMessage: 'Account holder name',
  },
  ibanLabel: {
    id: 'cc4.bank-details.iban-label',
    defaultMessage: 'IBAN',
  },
  holderAddressLabel: {
    id: 'cc4.bank-details.holder-address-label',
    defaultMessage: 'Account holder address',
  },
  cityLabel: {
    id: 'cc4.bank-details.city-label',
    defaultMessage: 'City',
  },
  zipCodeLabel: {
    id: 'cc4.bank-details.zip-code-label',
    defaultMessage: 'Zip Code',
  },
  bankDetails: {
    id: 'cc4.bank-details-header',
    defaultMessage: 'Bank details',
  },
  accountNumberLabel: {
    id: 'cc4.bank-details-account-number-label',
    defaultMessage: 'Account number',
  },
  bankDetailsSubHeader: {
    id: 'cc4.bank-details-sub-header',
    defaultMessage:
      'This is the information from your bank and where the bank is located. We need this information to transfer the compensation',
  },
  bankNameLabel: {
    id: 'cc4.bank-details-name-label',
    defaultMessage: 'Bank name',
  },
  bankCodeLabel: {
    id: 'cc4.bank-details-code-label',
    defaultMessage: 'Bank code',
  },
  bicCodeLabel: {
    id: 'cc4.bank-details-bic-label',
    defaultMessage: 'Bic code',
  },
  bankAddessLabel: {
    id: 'cc4.bank-details-address-label',
    defaultMessage: 'Bank address',
  },
  ibanTitle: {
    id: 'cc4.bank-details-iban-title-tooltip',
    defaultMessage: 'IBAN (International Bank Account Number',
  },
  ibanContent: {
    id: 'cc4.bank-details-iban-content-tooltip',
    defaultMessage:
      'You can find your IBAN number by logging into your online banking platform or by checking your paper bank statement',
  },
  successUpdateDetails: {
    id: 'cc4.bank-details-success-msg',
    defaultMessage: 'You have successfully update your bank details',
  },
  errorUpdateDetails: {
    id: 'cc4.bank-details-error-msg',
    defaultMessage: "Couldn't update your details, please try again.",
  },
  thankYouTitle: {
    id: 'cc4.bank-details-thank-you-title',
    defaultMessage: 'Thank you.',
  },
  saveAndReview: {
    id: 'cc4.bank-details-save-and-review-button',
    defaultMessage: 'Save and review',
  },
  saveOnly: {
    id: 'cc4.bank-details-save-only-button',
    defaultMessage: 'Or save only',
  },
  shareNewsLabel: {
    id: 'cc4.bank-details-share-news-label',
    defaultMessage: 'Share the good news on',
  },
  flightNumberLabel: {
    id: 'cc4.bank-details-flight-number-label',
    defaultMessage: 'Flight number',
  },
  flightDateLabel: {
    id: 'cc4.bank-details-flight-date-label',
    defaultMessage: 'Flight date',
  },
  showMoreLabel: {
    id: 'cc4.bank-details-show-more-label',
    defaultMessage: 'Show more',
  },
  showLessLabel: {
    id: 'cc4.bank-details-show-less-label',
    defaultMessage: 'Show less',
  },
  passengersLabel: {
    id: 'cc4.bank-details-compensation-passengers-label',
    defaultMessage: 'Compensation for passengers',
  },
  totalLabel: {
    id: 'cc4.bank-details-total-label',
    defaultMessage: 'Total',
  },
  feesLabel: {
    id: 'cc4.bank-details-fees-label',
    defaultMessage: 'Our fee',
  },
  legalFeesLabel: {
    id: 'cc4.bank-details-legal-fees-label',
    defaultMessage: 'Our legal fee',
  },
  totalExtraCostsLabel: {
    id: 'cc4.bank-details-extra-costs-label',
    defaultMessage: 'Extra costs',
  },
  perPassengerLabel: {
    id: 'cc4.bank-details-per-passenger-label',
    defaultMessage: 'per passenger',
  },
  willReceiveLabel: {
    id: 'cc4.bank-details-will-receive-label',
    defaultMessage: 'You will receive',
  },
  dataProtectionLabel: {
    id: 'cc4.bank-details-flight-data-protection-label',
    defaultMessage: 'Your personal data is protected and secured.',
  },
  subscriptionHeadLabel: {
    id: 'cc4.bank-details-subscription-header-label',
    defaultMessage: 'Flight Delayed premium',
  },
  subscriptionPriceLabel: {
    id: 'cc4.bank-details-subscription-price-label',
    defaultMessage: '€30/year',
  },
  subscriptionContentLabel: {
    id: 'cc4.bank-details-subscription-content-label',
    defaultMessage:
      'Get more out of your next claim with Flight Delayed Premium!',
  },
  subscriptionContentMoreInfo: {
    id: 'cc4.bank-details-subscription-content-more-info-label',
    defaultMessage: 'More information',
  },
  subscriptionCompenstationLabel: {
    id: 'cc4.bank-details-subscription-compensation-label',
    defaultMessage: '100% compensation',
  },
  subscriptionCommissionLabel: {
    id: 'cc4.bank-details-subscription-commission-label',
    defaultMessage: '0% commission',
  },
  subscriptionCardInfoLabel: {
    id: 'cc4.bank-details-subscription-card-info-label',
    defaultMessage: 'No credit card needed',
  },
  lastCheckTitle: {
    id: 'cc4.bank-details-subscription-last-check-title',
    defaultMessage: 'Last check',
  },
  lastCheckSubTitle: {
    id: 'cc4.bank-details-subscription-last-check-sub-title',
    defaultMessage:
      'Good news! We are almost ready to pay-out your compensation. We only need some additional bank details to finish the transfer.',
  },
  premiumLabel: {
    id: 'cc4.bank-details-subscription-premium-title',
    defaultMessage: 'Flight Delayed Premium',
  },
  premiumRegisterLabel: {
    id: 'cc4.bank-details-subscription-premium-register-date-label',
    defaultMessage: 'Registration date:',
  },
  premiumPassengerLabel: {
    id: 'cc4.bank-details-subscription-premium-passenger-label',
    defaultMessage: 'Protected passenger:',
  },
  premiumEndDateLabel: {
    id: 'cc4.bank-details-subscription-premium-end-date-label',
    defaultMessage: 'You will be protected until:',
  },
  premiumStartDateLabel: {
    id: 'cc4.bank-details-subscription-premium-start-date-label',
    defaultMessage: 'Starting date:',
  },
  premiumCreditsLabel: {
    id: 'cc4.bank-details-subscription-premium-credits-label',
    defaultMessage: 'Claim credits:',
  },
  premiumCreditsInfo: {
    id: 'cc4.bank-details-subscription-premium-credits-info',
    defaultMessage: '3 eligible claims, 1 passenger',
  },
  subscriptionCompensationLabel: {
    id: 'cc4.bank-details-subscription-compensation-check-label',
    defaultMessage: 'Compensation',
  },
  premiumPrice: {
    id: 'cc4.bank-details-subscription-premium-price',
    defaultMessage: '-€30',
  },
  subscriptionSideHeader: {
    id: 'cc4.bank-details-subscription-side-header',
    defaultMessage: 'Subscribe to Flight-Delayed premium',
  },
  subscriptionSideFooterText: {
    id: 'cc4.bank-details-subscription-side-footer-text',
    defaultMessage: 'Buy now and be protected for claims from',
  },
  premiunSubscriptionTitle: {
    id: 'cc4.bank-details-subscription-login-premium-title',
    defaultMessage: 'Premium subscription',
  },
  premiunSubscriptionSubTitle: {
    id: 'cc4.bank-details-subscription-login-premium-subtitle',
    defaultMessage:
      'Complete the payment for Premium and get 0% commission on your first 3 fligths that are allegeable for compensation',
  },
  flightCodePlaceholder: {
    id: 'cc4.form.search-flights.field.placeholder.flight-code',
    defaultMessage: 'e.g. KL875',
  },
  premiumDescriptionText: {
    id: 'cc4.bank-details-subscription-premium-description-text',
    defaultMessage:
      'With Flight Delayed Premium, you will receive 100% compensation on your next 3 successful claims for flights taking place between {start_date} and {end_date}.',
  },
  termsAndConditions: {
    id: 'cc4.bank-details.subscription-terms-conditions',
    defaultMessage:
      'I accept the Yource Premium Service <bold>Terms & Conditions</bold>',
  },
});
