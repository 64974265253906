import React from 'react';
import UploadTripIcon from '../../../assets/icons/upload-trip-icon.svg';
import ManualTripIcon from '../../../assets/icons/manual-trip-icon.svg';
import { useTranslatedNavigate } from '../../../utils/routes';
import routes from '../../../messages/routes';
import messages from './messages';
import { useIntl } from 'react-intl';

const SelectUploadOption = (props: any) => {
  const { type, setUploadBooking } = props;
  const translatedNavigate = useTranslatedNavigate();
  const { formatMessage } = useIntl();

  const handleUpload = () => {
    setUploadBooking(true);
  };

  const moveToManualEntry = () => {
    translatedNavigate(routes.enterManual);
  };

  return (
    <div className="upload-option-container flex">
      {type === 'upload' ? (
        <>
          <div className="upload-option-image-box" style={borderStyle}>
            <img src={UploadTripIcon} alt="" />
          </div>
          <div className="upload-text flex">
            <span className="upload-text-header">
              {formatMessage(messages.uploadBookingConfirmationLabel)}
            </span>
            <span className="upload-text-subheader">
              {formatMessage(messages.uploadFiveMinsLabel)}
            </span>
          </div>
          <div className="upload-button" onClick={handleUpload}>
            <button>{formatMessage(messages.startUploading)}</button>
          </div>
        </>
      ) : (
        <>
          <div className="upload-option-image-box" style={borderStyle}>
            <img src={ManualTripIcon} alt="" />
          </div>
          <div className="upload-text flex">
            <span className="upload-text-header">
              {formatMessage(messages.manualEntry)}
            </span>
            <span className="upload-text-subheader">
              {formatMessage(messages.optionsManualSubLabel)}
            </span>
          </div>
          <div className="manual-button">
            <button onClick={moveToManualEntry}>
              {formatMessage(messages.startManualLabel)}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SelectUploadOption;

const borderStyle = {
  borderRadius: '8px',
  border: '1px solid #d8d8da',
};
