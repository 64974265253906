import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import PlusIcon from '../../../../assets/icons/plus-icon.inline.svg';
import MinusIcon from '../../../../assets/icons/minus-icon.inline.svg';
import { isMobile } from 'react-device-detect';

interface QuestionRowProps {
  question: string;
  answer: string;
  hasSearchedText: boolean;
  resultsLength?: number;
  noMaxWidth?: boolean;
}

const QuestionRow = ({
  question,
  answer,
  hasSearchedText,
  resultsLength,
  noMaxWidth = false,
}: QuestionRowProps): JSX.Element => {
  const [folded, setFolded] = useState(resultsLength === 1 ? false : true);

  function toggleAnswer() {
    setFolded(!folded);
  }

  return (
    <div
      className="border-b border-gray-700 md:px-10 md:mx-10 py-10"
      style={
        isMobile || (hasSearchedText && !noMaxWidth)
          ? { maxWidth: '590px' }
          : { width: '100%' }
      }
    >
      <span
        className="search-bar-class py-10 leading-relaxed cursor-pointer relative justify-between font-medium text-lg text-black flex items-center"
        onClick={toggleAnswer}
      >
        <span>{question}</span>
        {folded ? <PlusIcon /> : <MinusIcon />}
      </span>
      <CSSTransition
        in={!folded}
        // needs to be equal to the transition duration
        timeout={{
          appear: 300,
          enter: 300,
        }}
        classNames="display-"
        unmountOnExit={true}
        mountOnEnter={true}
        appear={true}
        enter={true}
        exit={true}
      >
        <article
          className="m-0 pt-10 pb-25 font-normal text-sm"
          style={{ color: '#6f6c90d9', lineHeight: '30px' }}
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </CSSTransition>
    </div>
  );
};

export default QuestionRow;
