import React from 'react';
import AccountContainer from '../../../../components/AccountContainer';
import subscriptionSideMenuItems from '../subscriptionSideMenuItems';
import Card from '../../../../components/Card';
import Plan from './Plan';
import { useIntl } from 'react-intl';
import messages from '../messages';

const MySubscription = () => {
  const { formatMessage } = useIntl();

  return (
    <AccountContainer menuItems={subscriptionSideMenuItems}>
      <Card style={cardStyles}>
        <span className="my-subscription-header">
          {formatMessage(messages.yourSubscriptionLabel)}
        </span>
        <br />
        <Plan />
      </Card>
    </AccountContainer>
  );
};

export default MySubscription;

const cardStyles = {
  borderRadius: '12px',
  boxShadow: '0px 4px 8px 0px #D8D8DA',
};
