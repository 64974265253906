import React, { useEffect, useState } from 'react';
import Card from '../../../../components/Card';
import ArrowBack from '../../../../assets/icons/arrow-back-icon.svg';
import UploadFile from './UploadFile';
import useAuthenticate from '../../../../hooks/useAuthenticate';
import { jsonApiAxios } from '../../../../services/api';
import FlightsPage from './FlightsPage';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { useTranslatedNavigate } from '../../../../utils/routes';
import routes from '../../../../messages/routes';

const dossierItemSchema = {
  attributes: ['file', 'flights'],
  file: {
    ref: 'id',
  },
};

const subscriptionFlightsSchema = {
  attributes: [
    'subscriptionTrip',
    'flight_code',
    'flight_date',
    'departureAirport',
    'airline',
    'arrivalAirport',
  ],
  subscriptionTrip: {
    ref: 'id',
  },
  departureAirport: {
    ref: 'id',
  },
  airline: {
    ref: 'id',
  },
  arrivalAirport: {
    ref: 'id',
  },
};

const UploadDocument = (props: any) => {
  const { setUploadBooking, setAddTrip } = props;
  const [fileId, setfileId] = useState('');
  const [isLoadingTrip, setIsLoadingTrip] = useState(false);
  const [flightsFound, setFlightsFound] = useState([]);
  const [flightsToSave, setFlightsToSave] = useState([]);
  const [fileStatus, setFileStatus] = useState('');
  const [errorReason, setErrorReason] = useState('');
  const [tripId, setTripId] = useState('');
  const { token } = useAuthenticate();
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  const { formatMessage } = useIntl();
  const translatedNavigate = useTranslatedNavigate();

  const handleBack = () => {
    setUploadBooking(false);
  };

  const handleContinue = async () => {
    if (fileId) {
      setIsLoadingTrip(true);
      const trip = await jsonApiAxios.post(
        '/subscriptionTrips',
        {
          file: {
            id: fileId,
          },
        },
        {
          schema: dossierItemSchema,
          type: 'subscriptionTrips',
          headers,
        }
      );

      if (trip?.length === 1 && trip[0]?.status_reason === 'no_flights') {
        setErrorReason(trip[0]?.status_reason);
        setIsLoadingTrip(false);
        return;
      }

      if (trip?.length) {
        setTripId(trip[0]?.id);
        setFileStatus(trip[0]?.file_status);
      }
    }
  };

  const fetchTrip = async () => {
    if (fileId && tripId) {
      await jsonApiAxios.get(`/subscriptionTrips/${tripId}`, {
        headers,
      });
    }
  };

  useEffect(() => {
    if (fileId) {
      handleContinue();
    }
  }, [fileId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (fileStatus !== 'Finished') {
        fetchTrip().then(res => {
          if (res?.errors?.[0]?.status === '404') {
            clearInterval(interval);
          }
        });
      }
    }, 3000);

    if (fileStatus === 'Finished') {
      setIsLoadingTrip(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [fileStatus]);

  const addFlightToTrip = async () => {
    if (tripId) {
      const flightValues: any[] = [];
      flightsFound[0]?.flights?.forEach(item => {
        flightsToSave?.forEach(itemSave => {
          if (item?.id === itemSave.toString()) {
            flightValues.push({
              flight_code: item?.flight_code,
              flight_date: item?.flight_date,
              departureAirport: { id: item?.departureAirport?.id },
              arrivalAirport: { id: item?.arrivalAirport?.id },
              airline: { id: item?.airline?.id },
              subscriptionTrip: { id: tripId },
            });
          }
        });
      });
      for (const values of flightValues) {
        await attachFiles(values);
      }
      setUploadBooking(false);
      setAddTrip(false);
    }
  };

  const attachFiles = (values: any) => {
    return jsonApiAxios.post('/subscriptionFlights', values, {
      schema: subscriptionFlightsSchema,
      type: 'subscriptionFlights',
      headers,
    });
  };

  const moveToManual = () => {
    translatedNavigate(routes.enterManual);
  };

  return (
    <Card style={cardStyles}>
      {tripId && !isLoadingTrip ? (
        <div className="select-trip-container">
          <div className="select-trip-header">
            <div className="select-trip-back" onClick={handleBack}>
              <img
                src={ArrowBack}
                alt=""
                width={6}
                height={12}
                style={{ marginLeft: '4px' }}
              />
              <span>Back</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="select-trip-main-header">
                {formatMessage(messages.flightsFoundLabel)}
              </span>
              <span className="select-trip-main-subheader">
                {formatMessage(messages.manualEntrySubTitle)}
              </span>
            </div>
          </div>
          <FlightsPage
            tripId={tripId}
            setFlightsToSave={setFlightsToSave}
            setFlightsFound={setFlightsFound}
            errorReason={errorReason}
          />
          <div className="sub-flights-found-text">
            <span style={{ color: '#A8A8AA' }}>
              {formatMessage(messages.wrongLabel)}
            </span>{' '}
            <span
              style={{
                color: '#279C74',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={moveToManual}
            >
              {formatMessage(messages.enterManuallyLable)}
            </span>
          </div>
          <div className="footer-button">
            <button onClick={addFlightToTrip} disabled={!flightsToSave?.length}>
              {formatMessage(messages.saveTripsLabel)}
            </button>
          </div>
        </div>
      ) : (
        <div className="select-trip-container">
          <div className="select-trip-header">
            <div className="select-trip-back" onClick={handleBack}>
              <img
                src={ArrowBack}
                alt=""
                width={6}
                height={12}
                style={{ marginLeft: '4px' }}
              />
              <span>Back</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="select-trip-main-header">
                {formatMessage(messages.protectNewTripLabel)}
              </span>
              <span className="select-trip-main-subheader">
                {formatMessage(messages.manualEntrySubTitle)}
              </span>
            </div>
          </div>
          <UploadFile
            setfileId={setfileId}
            isLoadingTrip={isLoadingTrip}
            fileId={fileId}
            setErrorReason={setErrorReason}
            errorReason={errorReason}
          />
          {errorReason !== 'no_flights' && (
            <div className="footer-button">
              <button onClick={handleContinue} disabled={!fileId}>
                {formatMessage(messages.continueButton)}
              </button>
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

export default UploadDocument;

const cardStyles = {
  borderRadius: '12px',
  boxShadow: '0px 4px 8px 0px #D8D8DA',
};
