import React, { useEffect, useState } from 'react';
import Card from '../../../components/Card';
import { isMobile } from 'react-device-detect';
import QuestionRow from '../Support/HelpContent/QuestionRow';
import messages from './messages';
import { useIntl } from 'react-intl';
import translations from '../../../translations/main.json';
import { useTranslatedNavigate } from '../../../utils/routes';
import helpMessages from '../Support/newMessages';

const PremiumFaq = ({ faq }: { faq: any[] }) => {
  const { formatMessage } = useIntl();
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [counter, setCounter] = useState(5);
  const translatedNavigate = useTranslatedNavigate();

  useEffect(() => {
    if (selectedAnswer === 'no') {
      if (counter > 0) {
        setTimeout(() => setCounter(counter - 1), 1000);
      } else {
        goToContactPage();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, selectedAnswer]);

  const goToContactPage = () => {
    translatedNavigate([
      '/',
      translations['cc4.seo.route.account'],
      translations['cc4.account-page.help.message-contact-us-route'],
    ]);
  };

  return (
    <Card
      style={{ ...cardStyles, ...{ marginTop: isMobile ? '0px' : '28px' } }}
    >
      <span style={h1Styles}>
        {formatMessage(messages.howPremiumWorksTitle)}
      </span>
      <div
        className="flex flex-col faq-container"
        style={contentContainerStyles}
      >
        {faq?.length
          ? faq?.map((item: any) => (
              <QuestionRow
                key={item?.id}
                question={item?.question}
                answer={item?.answer}
                hasSearchedText={true}
                resultsLength={faq?.length}
                noMaxWidth={true}
              />
            ))
          : null}
        <div className="flex justify-between" style={footerStyles}>
          <span>
            {formatMessage(helpMessages.helpFooterConfirmationButtonTitle)}
          </span>
          <div className="flex flex-col">
            <div className="flex" style={{ gap: '14px' }}>
              <button
                style={buttonStyles}
                disabled={selectedAnswer === 'no'}
                onClick={() => setSelectedAnswer('yes')}
              >
                {' '}
                {formatMessage(helpMessages.helpFooterButtonYes)}
              </button>
              <button
                style={buttonStyles}
                disabled={selectedAnswer === 'yes'}
                onClick={() => setSelectedAnswer('no')}
              >
                {formatMessage(helpMessages.helpFooterButtonNo)}
              </button>
            </div>
            {selectedAnswer === 'no' ? (
              <div style={{ padding: '10px 0' }}>
                <span
                  style={{
                    fontSize: '13px',
                    fontWeight: 300,
                    lineHeight: '24px',
                    color: '#101828',
                    float: 'right',
                    marginTop: isMobile ? '-20px' : '0px',
                  }}
                >
                  {formatMessage(helpMessages.helpFooterRedirectText, {
                    counter: <span>{counter}</span>,
                  })}
                </span>
              </div>
            ) : selectedAnswer === 'yes' ? (
              <div style={{ padding: '10px 0' }}>
                <span
                  style={{
                    fontSize: '13px',
                    fontWeight: 300,
                    lineHeight: '24px',
                    color: '#101828',
                    float: 'right',
                  }}
                >
                  {formatMessage(helpMessages.helpFooterFeedbackText)}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PremiumFaq;

const cardStyles = {
  borderRadius: '12px',
  boxShadow: isMobile ? '' : '0px 4px 8px 0px #D8D8DA',
  padding: '48px',
  display: 'flex',
  gap: '24px',
  flexDirection: 'column',
  alignItems: 'center',
};

const h1Styles = {
  fontSize: '24px',
  fontWeight: '700',
  lineHeight: '25.92px',
  color: '#000000',
};

const contentContainerStyles = {
  width: '801px',
  gap: '34px',
};

const footerStyles = {
  borderRadius: '12px',
  backgroundColor: '#F8F8F9',
  padding: '20px',
  fontWeight: '600',
  fontSize: '16px',
  color: '#101828',
  alignItems: 'center',
};

const buttonStyles = {
  backgroundColor: '#667085',
  padding: '10px 16px',
  borderRadius: '8px',
  color: '#FFFFFF',
  fontWeight: 500,
  fontSize: '14px',
};
