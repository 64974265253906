import React from 'react';
import Card from '../Card';
import messages from '../../views/BankDetails/messages';
import { useIntl } from 'react-intl';
import SubscriptionSidePremiumInfo from './SubscriptionSidePremiumInfo';

const SubscriptionSideComponent = () => {
  const { formatMessage } = useIntl();

  return (
    <Card className="subscription-side-card-container">
      <div className="subscription-side-container">
        <div className="subscription-side-header">
          <span>{formatMessage(messages.subscriptionSideHeader)}</span>
        </div>
        <div className="subscription-side-price-header">
          <span className="subscription-side-price">€30,00</span>{' '}
          <span className="subscription-side-price-duration">Per year</span>
        </div>
        <div className="subscription-side-premium-title">
          <span>{formatMessage(messages.subscriptionHeadLabel)}</span>
        </div>
        <SubscriptionSidePremiumInfo />
      </div>
    </Card>
  );
};

export default SubscriptionSideComponent;
