import React, { useState } from 'react';
import ArrowRight from '../../../assets/images/account-page/arrowRight.svg';
import ArrowDown from '../../../assets/icons/arrow-down-icon.svg';
import ArrowUp from '../../../assets/icons/arrow-up-icon.svg';
import PlaneSubscription from '../../../assets/icons/plane-subscription-icon.svg';
import AlertTriangleIcon from '../../../assets/icons/triangle-alert-icon.svg';
import { useIntl } from 'react-intl';
import messages from './messages';
import config from '../../../config';
import moment from 'moment';

const locale = {
  en: 'en-gb',
  nl: 'nl',
  es: 'es',
  pl: 'pl',
  be: 'fr',
  fr: 'fr',
  it: 'it',
  de: 'de',
  da: 'da',
};

/* tslint:disable no-var-requires */
require(`moment/locale/${locale[config?.locale] || 'en-gb'}`);
interface FlightSubscriptionInterface {
  flight_date: Date;
  flight_code: string;
  departureAirport: {
    iata: string;
  };
  arrivalAirport: {
    iata: string;
  };
  airline: {
    name: string;
  };
}

const FlightComponent = (props: any) => {
  const [open, setOpen] = useState(true);
  const [fightsToAdd, setFlightsToAdd] = useState([]);
  const { data, setFlightsToSave, addFlight = false, errorReason = '' } = props;
  const { formatMessage } = useIntl();

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleFlight = (flightId: any) => {
    if (!fightsToAdd.length) {
      setFlightsToAdd([flightId]);
      if (setFlightsToSave) {
        setFlightsToSave([flightId]);
      }
    } else {
      setFlightsToAdd((prevItems: any) => {
        if (prevItems.includes(flightId)) {
          return prevItems.filter((item: string) => item !== flightId);
        } else {
          return [...prevItems, flightId];
        }
      });
      if (setFlightsToSave) {
        setFlightsToSave((prevItems: any) => {
          if (prevItems.includes(flightId)) {
            return prevItems.filter((item: string) => item !== flightId);
          } else {
            return [...prevItems, flightId];
          }
        });
      }
    }
  };

  return (
    <>
      {data?.[0]?.flights?.map(
        (item: FlightSubscriptionInterface, index: number) => (
          <div
            key={item?.id}
            className="flight-subscription-container"
            style={index > 0 ? { marginTop: '20px' } : {}}
          >
            <span className="flight-date" style={{ opacity: '0.5' }}>
              {moment(item?.flight_date).format('Do MMMM YYYY')}
            </span>
            <div className="flight-box-container overflow-hidden">
              <div className="box-header flex">
                <div className="box-header-info flex">
                  {addFlight && (
                    <div style={{ marginRight: '15px' }}>
                      <input
                        type="checkbox"
                        disabled={
                          errorReason ===
                          'flights_not_at_least_48_hours_in_future'
                        }
                        onClick={() =>
                          handleFlight(item?.id || item?.flight_date)
                        }
                      />
                    </div>
                  )}
                  <div className="airports">
                    <span className="iata-info">
                      {item?.departureAirport?.iata}
                    </span>
                    <img src={ArrowRight} alt="" width={15} height={15} />
                    <span className="iata-info">
                      {item?.arrivalAirport?.iata}
                    </span>
                  </div>
                  <div className="vertical-line" />
                  <span>{item?.airline?.name}</span>
                  <div className="vertical-line" />
                  <span>{item?.flight_code}</span>
                </div>
                <div className="box-header-arrow">
                  <img
                    src={open ? ArrowUp : ArrowDown}
                    alt=""
                    style={{ cursor: 'pointer' }}
                    onClick={handleOpen}
                  />
                </div>
              </div>
              {open ? (
                <div className="box-content flex">
                  <div className="content-container flex">
                    <div className="flight-info flex">
                      <span className="iata-info">
                        ({item?.departureAirport?.iata})
                      </span>
                      <hr style={{ marginLeft: '15px' }} />
                      <img
                        src={PlaneSubscription}
                        alt=""
                        width={24}
                        height={24}
                      />
                      <hr style={{ marginRight: '15px' }} />
                      <span className="iata-info">
                        ({item?.arrivalAirport?.iata})
                      </span>
                    </div>
                    <div>
                      <span className="flight-nr" style={{ opacity: '50%' }}>
                        {formatMessage(messages.subscriptionFlightNr)}{' '}
                        {item?.flight_code}
                      </span>
                      <span
                        className="flight-date"
                        style={{ marginLeft: '12px', opacity: '50%' }}
                      >
                        {formatMessage(messages.subscriptionFlightDate)}{' '}
                        {moment(item?.flight_date).format('L')}
                      </span>
                    </div>
                  </div>
                  {errorReason ===
                    'flights_not_at_least_48_hours_in_future' && (
                    <div className="flex" style={warningMsgStyles}>
                      <img
                        src={AlertTriangleIcon}
                        alt=""
                        width={24}
                        height={24}
                      />
                      <span style={textErrorStyles}>
                        {formatMessage(messages.hours48FlightsError)}
                      </span>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default FlightComponent;

const warningMsgStyles = {
  gap: '12px',
  padding: '6px',
  borderRadius: '6px',
  backgroundColor: '#FCF6DD',
  marginTop: '12px',
  marginBottom: '12px',
  height: '63px',
};

const textErrorStyles = {
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '16.94px',
  letterSpacing: '-0.02em',
  textAlign: 'left',
};
