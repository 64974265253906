import React, { useState } from 'react';
import StepperRoute from './StepperRoute';
import StepperInfo from './StepperInfo';
import messages from '../messages';
import { useIntl } from 'react-intl';

interface StepperProps {
  steps: { label: string }[];
  activeStep: number;
  setActiveStep: any;
  setFlights: any;
  setFlightsData: any;
  flightData: any;
}

const Stepper: React.FC<StepperProps> = ({
  steps,
  activeStep,
  setActiveStep,
  setFlights,
  setFlightsData,
  flightData,
}) => {
  const [hasAirportsData, setHasAirportsData] = useState(false);
  const [departure, setDeparture] = useState();
  const [arrival, setArrival] = useState();
  const [stopovers, setStopovers] = useState([]);
  const [editIdx, setEditIdx] = useState(0);
  const { formatMessage } = useIntl();

  return (
    <div className="stepper-container">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div style={{ display: 'flex' }}>
            {/* Step circle */}
            <div
              className={`step-circle ${index <= activeStep ? 'active' : ''}`}
            >
              {index + 1}
            </div>

            {/* Line between steps */}
            {/* {index < steps.length - 1 && ( */}
            <div className="step-line-container">
              <div
                className={`step-line ${index < activeStep ? 'completed' : ''}`}
              />
            </div>
            {/* )} */}

            {/* Step label */}
            {index === 0 ? (
              <div style={{ marginBottom: '15px' }}>
                <div className="step-label">{formatMessage(step.label)}</div>
                <StepperRoute
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  setHasAirportsData={setHasAirportsData}
                  setDeparture={setDeparture}
                  setArrival={setArrival}
                  setStopovers={setStopovers}
                  stopovers={stopovers}
                  departure={departure}
                  arrival={arrival}
                />
              </div>
            ) : (
              <div>
                <div className="step-label">{formatMessage(step.label)}</div>
                {!hasAirportsData ? (
                  <div className="stepper-info-container">
                    <span>{formatMessage(messages.infoLabel)}</span>
                  </div>
                ) : (
                  <>
                    <StepperInfo
                      departureFlight={departure}
                      arrivalFlight={stopovers[0]}
                      setFlights={setFlights}
                      editIdx={editIdx}
                      setEditIdx={setEditIdx}
                      index={0}
                      total={stopovers?.length}
                      setActiveStep={setActiveStep}
                      setFlightsData={setFlightsData}
                      flightData={flightData}
                    />
                    {stopovers?.length === 1 ? (
                      <StepperInfo
                        departureFlight={stopovers[0]}
                        arrivalFlight={arrival}
                        setFlights={setFlights}
                        editIdx={editIdx}
                        setEditIdx={setEditIdx}
                        index={1}
                        total={stopovers?.length}
                        setActiveStep={setActiveStep}
                        setFlightsData={setFlightsData}
                        flightData={flightData}
                      />
                    ) : (
                      stopovers?.map(stoppover => (
                        <>
                          <StepperInfo
                            departureFlight={departure}
                            arrivalFlight={stopovers[0]}
                            setFlights={setFlights}
                            editIdx={editIdx}
                            setFlightsData={setFlightsData}
                          />
                        </>
                      ))
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Stepper;
