import { defineMessages } from 'react-intl';

export default defineMessages({
  personalInfo: {
    id: 'cc4.form.client-account.personal-info',
    defaultMessage: `Personal information`,
  },
  contactInfo: {
    id: 'cc4.form.client-account.contact-info',
    defaultMessage: `Contact information`,
  },
  personalDetails: {
    id: 'cc4.form.client-account.personal-details',
    defaultMessage: `Personal details`,
  },
  yourEmailAddress: {
    id: 'cc4.form.client-account.label.email',
    defaultMessage: 'Your email address',
  },
  emailPlaceholder: {
    id: 'cc4.form.client-account.placeholder.email',
    defaultMessage: 'Enter your email',
  },
  updateDetails: {
    id: 'cc4.form.client-account.update-details',
    defaultMessage: 'Update details',
  },
  passwordTitle: {
    id: 'cc4.form.client-account.password-title',
    defaultMessage: `Change account password`,
  },
  changePassword: {
    id: 'cc4.form.client-account.change-password',
    defaultMessage: `Change password`,
  },
  newPassword: {
    id: 'cc4.form.client-account.new-password',
    defaultMessage: `New password`,
  },
  newPasswordPlaceholder: {
    id: 'cc4.form.client-account.new-password-placeholder',
    defaultMessage: `Type new password`,
  },
  currentPassword: {
    id: 'cc4.form.client-account.current-password',
    defaultMessage: `Current password`,
  },
  currentPasswordPlaceholder: {
    id: 'cc4.form.client-account.current-password-placeholder',
    defaultMessage: `Type current password`,
  },
  updatePassword: {
    id: 'cc4.form.client-account.new-password-button',
    defaultMessage: `Update password`,
  },
  myProfile: {
    id: 'cc4.form.client-account.my-profile',
    defaultMessage: `My profile`,
  },
  helpCenter: {
    id: 'cc4.form.client-account.help-center',
    defaultMessage: 'Help center',
  },
  messagesPageLink: {
    id: 'cc4.form.client-account.messages.messages-title',
    defaultMessage: 'Messages',
  },
  messagesHeaderStripeTitle: {
    id: 'cc4.form.client-account.messages.header-stripe-title',
    defaultMessage: 'MESSAGES',
  },
  messagesTitle: {
    id: 'cc4.form.client-account.messages.title',
    defaultMessage: 'Ask us for help with your claim',
  },
  messagesSubtitle: {
    id: 'cc4.form.client-account.messages.subtitle',
    defaultMessage:
      'We are here to help! Contact us by sending a message, we will reply as soon as possible.',
  },
  messagesNewMessageButtonTitle: {
    id: 'cc4.form.client-account.messages.new-message-button-title',
    defaultMessage: 'New message',
  },
  messagesReferenceDropdownTitle: {
    id: 'cc4.form.client-account.messages.reference-dropdown-title',
    defaultMessage: 'Reference',
  },
  messagesReferenceDropdownPlaceholder: {
    id: 'cc4.form.client-account.messages.reference-dropdown-placeholder',
    defaultMessage: 'Select an option',
  },
  messagesTopicDropdownTitle: {
    id: 'cc4.form.client-account.messages.topic-dropdown-title',
    defaultMessage: 'What topic can we helop with?',
  },
  messagesTopicDropdownPlaceholder: {
    id: 'cc4.form.client-account.messages.topic-dropdown-placeholder',
    defaultMessage: 'Select an option',
  },
  messagesTopicDropdownDisabledPlaceholder: {
    id: 'cc4.form.client-account.messages.topic-dropdown-disabled-placeholder',
    defaultMessage: 'Select a claim first',
  },
  messagesMessageTextBoxTitle: {
    id: 'cc4.form.client-account.messages.message-textbox',
    defaultMessage: 'Message',
  },
  messagesMessageTextBoxPlaceholder: {
    id: 'cc4.form.client-account.messages.message-textbox-placeholder',
    defaultMessage: 'Type your message...',
  },
  messagesMessageSendButton: {
    id: 'cc4.form.client-account.messages.message-send-button',
    defaultMessage: 'Send',
  },
  messagesMessageGeneralTag: {
    id: 'cc4.form.client-account.messages.message-general-tag',
    defaultMessage: 'General',
  },
  messagesMessageAllMessagesTag: {
    id: 'cc4.form.client-account.messages.message-all-messages',
    defaultMessage: 'All messages',
  },
  messagesMessageFilterTitle: {
    id: 'cc4.form.client-account.messages.message-filter-title',
    defaultMessage: 'Filter',
  },
  messagesMessageNoMessageFoundText: {
    id: 'cc4.form.client-account.messages.message-no-message-found-text',
    defaultMessage: 'No messages found',
  },
  messagesMessageStatus: {
    id: 'cc4.form.client-account.messages.message-status',
    defaultMessage: 'Your message has been successfully sent',
  },
  messageDoneUpdatingProfile: {
    id: 'cc4.form.client-account.update-details-status',
    defaultMessage: 'Done! Your profile has been updated',
  },
  subscriptionOverview: {
    id: 'cc4.form.client-account.subscription-overview',
    defaultMessage: 'Overview',
  },
  subscriptionSideMenuTitle: {
    id: 'cc4.form.client-account.subscription-side-menu-title',
    defaultMessage: 'Subscription',
  },
  subscriptionContactSideMenuTitle: {
    id: 'cc4.form.client-account.contact-side-menu-title',
    defaultMessage: 'Contact us',
  },
});
