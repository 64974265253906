import React from 'react';
import swirlsImg from '../../assets/images/swirls.png';
import StatusPaymentComponent from './StatusPaymentComponent';
import ReceiptIcon from '../../assets/icons/receipt-icon.svg';
import { useTranslatedNavigate } from '../../utils/routes';
import translations from '../../translations/main.json';
import { useIntl } from 'react-intl';
import messages from '../Account/Subscription/messages';
// import { useJsonApi } from '../../services/GlobalStore/GlobalStore'
// import useAuthenticate from "../../hooks/useAuthenticate";

if (typeof document !== 'undefined') {
  const body = document.getElementsByTagName('body')[0];
  body.style.backgroundImage = `url(${swirlsImg})`;
  body.style.backgroundPosition = 'center';
  body.style.backgroundRepeat = 'no-repeat';
  body.style.backgroundSize = 'cover';
}

const SuccessPage = () => {
  const translatedNavigate = useTranslatedNavigate();
  const { formatMessage } = useIntl();
  // const { token } = useAuthenticate();
  // const { data: flightsData, loading: subscriptionLoading } = useJsonApi(
  //     `/subscriptions`,
  //     {
  //       cachePolicy: 'no-cache',
  //       queryParams: {
  //         include: [
  //           'subscriptionTrips',
  //           'subscriptionTrips.subscriptionFlights.airline',
  //           'subscriptionTrips.subscriptionFlights.departureAirport',
  //           'subscriptionTrips.subscriptionFlights.arrivalAirport',
  //         ].join(),
  //       },
  //       headers: { Authorization: `Bearer ${token}` },
  //     },
  //     []
  //   );

  const handleGoToAccount = () => {
    translatedNavigate(
      '/' +
        translations['cc4.seo.route.account'] +
        '/' +
        translations['cc4.seo.route.my-premium']
    );
  };

  return (
    <StatusPaymentComponent
      title={formatMessage(messages.paymentSuccessStatus)}
      subTitle={formatMessage(messages.paymentSuccessStatusText)}
      buttonText={formatMessage(messages.paymentSuccessStatusButtonText)}
      icon={ReceiptIcon}
      onClick={handleGoToAccount}
    />
  );
};

export default SuccessPage;
