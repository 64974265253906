import React, { useEffect, useState } from 'react';
import FlightComponent from '../FlightComponent';
import { isEmpty } from 'lodash';
import messages from '../messages';
import { useIntl } from 'react-intl';
import { jsonApiAxios } from '../../../../services/api';
import useAuthenticate from '../../../../hooks/useAuthenticate';
import { useTranslatedNavigate } from '../../../../utils/routes';
import routes from '../../../../../src/messages/routes';
import { useGlobalState } from '../../.../../../../services/GlobalStore/GlobalStore';

const dossierItemSchema = {
  attributes: ['file', 'flights'],
  file: {
    ref: 'id',
  },
};

const subscriptionFlightsSchema = {
  attributes: [
    'subscriptionTrip',
    'flight_code',
    'flight_date',
    'departureAirport',
    'airline',
    'arrivalAirport',
  ],
  subscriptionTrip: {
    ref: 'id',
  },
  departureAirport: {
    ref: 'id',
  },
  airline: {
    ref: 'id',
  },
  arrivalAirport: {
    ref: 'id',
  },
};

const tripSchema = {
  attributes: ['flights_status'],
};

const FlightResults = (props: any) => {
  const { departureFlight, returnFlight } = props;
  const [flightsData, setFlightsData] = useState<any[]>([]);
  const [flightsToSave, setFlightsToSave] = useState<any[]>([]);
  const { token } = useAuthenticate();
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  const translatedNavigate = useTranslatedNavigate();
  const { formatMessage } = useIntl();
  const [state] = useGlobalState();

  const getAirport = (item: any) => {
    const allAirports = Object.values(state?.airports);

    if (Array.isArray(item)) {
      const airportArray: any[] = [];
      item?.forEach(itemAirport => {
        allAirports?.forEach((airport: any) => {
          if (itemAirport.toString() === airport?.id) {
            airportArray.push(airport?.name);
          }
        });
      });
      return airportArray.join(' ,');
    } else {
      let selectedAirport = '';
      allAirports.forEach(airport => {
        if (airport?.id === item?.toString()) {
          selectedAirport = airport?.iata || airport?.name;
        }
      });
      return selectedAirport;
    }
  };

  const getAilrine = (item: any) => {
    const allAirlines = Object.values(state?.airlines);
    let selectedAirline = '';
    allAirlines.forEach(airline => {
      if (airline?.id === item) {
        selectedAirline = airline?.name;
      }
    });
    return selectedAirline;
  };

  useEffect(() => {
    if (!isEmpty(departureFlight)) {
      const transformData: any = {
        flights: [],
      };

      transformData.flights.push({
        flight_code: departureFlight?.flight_code,
        flight_date: departureFlight?.departure_date,
        departureAirport: {
          id: departureFlight?.departure_airport,
          iata: getAirport(departureFlight?.departure_airport),
        },
        arrivalAirport: {
          id: departureFlight?.arrival_airport,
          iata: getAirport(departureFlight?.arrival_airport),
        },
        airline: {
          id: departureFlight?.airline,
          name: getAilrine(departureFlight?.airline),
        },
      });
      setFlightsData([transformData]);
    }

    if (!isEmpty(returnFlight)) {
      const transformData: any = {
        flights: [],
      };

      transformData.flights.push({
        flight_code: returnFlight?.flight_code,
        flight_date: returnFlight?.departure_date,
        departureAirport: {
          id: returnFlight?.departure_airport,
          iata: getAirport(returnFlight?.departure_airport),
        },
        arrivalAirport: {
          id: returnFlight?.arrival_airport,
          iata: getAirport(returnFlight?.arrival_airport),
        },
        airline: {
          id: returnFlight?.airline,
          name: getAilrine(returnFlight?.airline),
        },
      });

      setFlightsData((prevState: any) => {
        prevState[0].flights.push(...transformData.flights);
        return prevState;
      });
    }
  }, [returnFlight, departureFlight]);

  const handleSaveTrips = async (isReturnFlight = false) => {
    const trip = await jsonApiAxios.post(
      '/subscriptionTrips',
      {},
      {
        schema: dossierItemSchema,
        type: 'subscriptionTrips',
        headers,
      }
    );

    if (trip?.id) {
      // const flightValues: any[] = [];
      const values = {
        subscriptionTrip: { id: trip?.id },
        airline: !isReturnFlight
          ? flightsData[0]?.flights?.[0]?.airline
          : flightsData[0]?.flights?.[1]?.airline,
        arrivalAirport: !isReturnFlight
          ? flightsData[0]?.flights?.[0]?.arrivalAirport
          : flightsData[0]?.flights?.[1]?.arrivalAirport,
        departureAirport: !isReturnFlight
          ? flightsData[0]?.flights?.[0]?.departureAirport
          : flightsData[0]?.flights?.[1]?.departureAirport,
        flight_code: !isReturnFlight
          ? flightsData[0]?.flights?.[0]?.flight_code
          : flightsData[0]?.flights?.[1]?.flight_code,
        flight_date: !isReturnFlight
          ? flightsData[0]?.flights?.[0]?.flight_date
          : flightsData[0]?.flights?.[1]?.flight_date,
      };
      await attachFiles(values);
      updateFlightStatus(trip?.id, isReturnFlight);
    }
  };

  const attachFiles = (values: any) => {
    return jsonApiAxios.post('/subscriptionFlights', values, {
      schema: subscriptionFlightsSchema,
      type: 'subscriptionFlights',
      headers,
    });
  };

  const updateFlightStatus = (tripId: string, isReturnFlight: boolean) => {
    jsonApiAxios
      .patch(
        `/subscriptionTrips/${tripId}`,
        {
          flights_status: 'Queued',
        },
        {
          schema: tripSchema,
          type: 'subscriptionTrips',
          headers,
        }
      )
      .then(res => {
        if (!isEmpty(returnFlight) && !isReturnFlight) {
          handleSaveTrips(true);
        } else {
          translatedNavigate(routes.myPremium);
        }
      });
  };

  return (
    <div>
      {flightsData?.length && (
        <>
          <FlightComponent
            data={flightsData}
            addFlight={true}
            setFlightsToSave={setFlightsToSave}
          />
          <div className="footer-button" style={{ marginTop: '20px' }}>
            <button
              onClick={() => handleSaveTrips()}
              disabled={!flightsToSave?.length}
            >
              {formatMessage(messages.saveTripsLabel)}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default FlightResults;
