import React, { useState, useRef } from 'react';
import UploadIcon from '../../../../assets/icons/upload-trip-icon.svg';
import BinIcon from '../../../../assets/icons/bin-icon-grey.svg';
import Dropzone from 'react-dropzone';
import useAuthenticate from '../../../../hooks/useAuthenticate';
import { useTranslatedNavigate } from '../../../../utils/routes';
import routes from '../../../../../src/messages/routes';
import { jsonApiAxios } from '../../../../services/api';
import Loading from '../../../../components/Loading';
import { useIntl } from 'react-intl';
import messages from '../messages';

const UploadFile = (props: any) => {
  const {
    setfileId,
    isLoadingTrip,
    fileId,
    errorReason,
    setErrorReason,
  } = props;
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState({});
  const [files, setFiles] = useState<any[]>([]);
  const { token } = useAuthenticate();
  const { formatMessage } = useIntl();
  const translatedNavigate = useTranslatedNavigate();

  const moveToManualEntry = () => {
    translatedNavigate(routes.enterManual);
  };

  // Ref to trigger file input
  const dropzoneRef = useRef<any>(null);

  const onDrop = acceptedFiles => {
    if (acceptedFiles?.length) {
      setErrorReason('');
      setFiles(acceptedFiles);
      acceptedFiles.forEach(async (file: any, index: number) => {
        const config = {
          onUploadProgress: progressEvent => {
            const percentageCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            setProgressPercentage(prevState => ({
              ...prevState,
              [acceptedFiles?.length + index]: percentageCompleted,
            }));
          },
        };
        try {
          setFileIsUploading(true);
          const formData = new FormData();
          formData.append('data[attributes][file]', file);
          formData.append('data[type]', 'files');
          const headers = token ? { Authorization: `Bearer ${token}` } : {};

          const { id: newFileId } = await jsonApiAxios
            .post('/files', formData, {
              noSerialize: true,
              ...config,
              headers: {
                'Content-Type': 'multipart/form-data',
                ...headers,
              },
            })
            .catch((error: any) => {
              setFileIsUploading(false);
              // handleError(error);
            });

          if (newFileId) {
            setfileId(newFileId);
            setFileIsUploading(false);
          }
          // const { id: fileId }  = await uploadFile({
          //   file,
          //   relationship,
          //   config: {
          //     headers: token ? { Authorization: `Bearer ${token}` } : {},
          //     ...config,
          //   },
          // })

          // let newFile = Object.assign(file, {
          //   preview: URL.createObjectURL(file),
          //   fileId,
          //   dossierItemId,
          // });

          // arrayHelpers.replace(values.length + index, newFile);

          // afterUploadCallback && afterUploadCallback(fileId, dossierItemId);
          // afterSuccess && (await afterSuccess());
          // setFileIsUploading(false);
        } catch (error) {
          // handleError(error);
        }
      });
    }
  };

  const removeFile = () => {
    setFiles([]);
    setfileId('');
  };

  // Trigger the Dropzone input click
  const handleUploadNewFileClick = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  return (
    <>
      <div
        className="upload-file-container"
        style={
          errorReason === 'no_flights'
            ? { borderColor: '#FB3B3B', borderWidth: '2px' }
            : {}
        }
      >
        <Dropzone onDrop={onDrop} ref={dropzoneRef}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input
                data-testid="dropzone"
                {...getInputProps()}
                accept=".doc,.docx,.xls,.xlsx,.pdf,.bmp,.jpg,.jpeg,.png,.gif,.rtf,.txt,.pdf"
              />
              <>
                <div className="upload-file-content">
                  {fileIsUploading ? (
                    <>
                      <div className="relative w-full px-20 pt-1">
                        <div className="file-placeholder-container">
                          <div className="file-placeholde-img-text">
                            <img
                              src={UploadIcon}
                              alt=""
                              width={45}
                              height={45}
                            />
                            <span>{files?.[0]?.name}</span>
                          </div>
                          <img
                            src={BinIcon}
                            alt=""
                            width={24}
                            height={24}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                        <div className="overflow-hidden h-5 mb-4 text-xs flex rounded bg-primary-10">
                          <div
                            style={{ width: `${progressPercentage[0]}%` }}
                            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-400"
                          />
                        </div>
                      </div>
                      <span className="sm:flex-grow ml-20 text-gray-300 truncate text-sm w-full sm:max-w-280">
                        Uploading document ...
                      </span>
                    </>
                  ) : isLoadingTrip ? (
                    <>
                      <Loading />
                      <span>{formatMessage(messages.analysingFile)}</span>
                    </>
                  ) : fileId && !isLoadingTrip ? (
                    <div className="relative w-full px-20 pt-1">
                      <div className="file-placeholder-container">
                        <div className="file-placeholde-img-text">
                          <img src={UploadIcon} alt="" width={45} height={45} />
                          <span>{files?.[0]?.name}</span>
                        </div>
                        <img
                          src={BinIcon}
                          alt=""
                          width={24}
                          height={24}
                          style={{ cursor: 'pointer' }}
                          onClick={removeFile}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <img src={UploadIcon} alt="" width={45} height={45} />
                      <span>{formatMessage(messages.uploadFileText)}</span>
                      <button>{formatMessage(messages.uploadButton)}</button>
                    </>
                  )}
                </div>
              </>
            </div>
          )}
        </Dropzone>
      </div>
      {errorReason === 'no_flights' && (
        <div>
          <span style={errorStyles}>
            {formatMessage(messages.uploadingNoFlightsError)}
          </span>
          <div className="flex justify-center" style={{ marginTop: '20px' }}>
            <button
              className="button-manual-entry"
              onClick={handleUploadNewFileClick}
            >
              Upload a new file
            </button>
            <button style={manuallButton} onClick={moveToManualEntry}>
              Add manually
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default UploadFile;

const errorStyles = {
  color: '#A12626',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16.94px',
  letterSpacing: '-0.02em',
  textAlign: 'center',
};

const manuallButton = {
  borderRadius: '6px',
  borderWidth: '1px',
  padding: '10px 20px',
  color: '#202020',
  fontSize: '14px',
  fontWeight: '600',
};
