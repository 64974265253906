import React from 'react';
import messages from './messages';
import { useIntl } from 'react-intl';

const ThankYouPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <h1>{formatMessage(messages.thankYouTitle)}</h1>
      <p
        className={'font-open-sans text-base mb-30 mt-0'}
        style={{ color: '#667085', fontSize: '16px' }}
      >
        {formatMessage(messages.successUpdateDetails)}
      </p>
    </>
  );
};

export default ThankYouPage;
