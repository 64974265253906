import React from 'react';

import AccountRouter from '../components/AccountRouter';
import PrivateRoute from '../components/PrivateRoute';
import routes from '../messages/routes';
import Account from '../views/Account';
import Password from '../views/Account/Password';
import ClaimsOverview from '../views/Account/ClaimsOverview/ClaimsOverview';
import ClaimDetails from '../views/Account/ClaimDetails/ClaimDetails';
import ClaimPassengerList from '../views/Account/ClaimDetails/ClaimPassengerList/ClaimPassengerList';
import Help from '../views/Account/Support/Help';
import HelpSuppotMessaging from '../views/Account/Support/HelpSuppotMessaging';
import SupportMessagesOverview from '../views/Account/Support/SupportMessagesOverview';
import RedirectLogic from '../views/Account/RedirectLogic';
import translations from '../translations/main.json';
import NotFoundPage from '../pages/404';
import ClaimContainer from '../views/Account/ClaimDetails';
import ClaimDocuments from '../views/Account/ClaimDetails/ClaimDocuments';
import BookingDetails from '../views/Account/BookingDetails/index';
import FlightItinerary from '../views/Account/FlightItinerary/index';
import DocumentSubpage from '../views/Account/ClaimDetails/ClaimDocuments/DocumentSubpage';
import PassengerDocumentUpload from '../views/DirectFeedbackUpload/PassengerDocumentUpload';
import PassengerForm from '../views/Passengers/PassengerForm';
import LegalGuardian from '../views/LegalGuardian';
import SearchFlightsForm from '../views/SearchFlightsForm';
import HelpByQuestion from '../views/Account/Support/HelpByQuestion';
import Premium from '../views/Account/Subscription/Premium'
import MySubscription from '../views/Account/Subscription/MySubscription'
import EnterManualFlights from '../views/Account/Subscription/EnterManualFlights'

const ClientAccount = () => {
  return (
    <AccountRouter basepath={routes.account}>
      <PrivateRoute path="/" component={RedirectLogic} />
      <PrivateRoute path={routes.myProfile} component={Account} />
      <PrivateRoute path={routes.password} component={Password} />
      <PrivateRoute path={routes.claimsOverview} component={ClaimsOverview} />
      <PrivateRoute
        path={
          translations['cc4.seo.route.shareable-claim-page'] + '/:claimNumber'
        }
        component={ClaimContainer}
      >
        <PrivateRoute path={'/'} component={ClaimDetails} />
        <PrivateRoute
          path={'/' + translations['cc4.seo.route.documents']}
          component={ClaimDocuments}
        />
        <PrivateRoute
          path={'/' + translations['cc4.seo.route.claims-booking-details']}
          component={BookingDetails}
        />
        <PrivateRoute
          path={'/' + translations['cc4.seo.route.claims-flight-itinerary']}
          component={FlightItinerary}
        />
        <PrivateRoute
          path={
            '/' +
            translations['cc4.seo.route.claims-flight-itinerary'] +
            '/' +
            translations['cc4.seo.route.claims-connecting-flight']
          }
          component={SearchFlightsForm}
        />
        <PrivateRoute
          path={
            '/' +
            translations['cc4.seo.route.claims-flight-itinerary'] +
            '/' +
            translations['cc4.seo.route.claims-replacement-flight']
          }
          component={SearchFlightsForm}
        />
        <PrivateRoute
          path={
            '/' +
            translations['cc4.seo.route.passenger'] +
            '/:passengerId/' +
            translations['cc4.seo.route.authorization']
          }
          // Above path wins with next one according to ReachRouter docs
          // https://reach.tech/router/ranking
          component={DocumentSubpage(PassengerForm)}
        />
        <PrivateRoute
          path={
            '/' +
            translations['cc4.seo.route.passenger'] +
            '/:passengerId/' +
            translations['cc4.seo.route.authorization'] +
            '/' +
            translations['cc4.seo.route.legal-guardian-info'] +
            '/:guardianIndex'
          }
          // Above path wins with next one according to ReachRouter docs
          // https://reach.tech/router/ranking
          component={DocumentSubpage(LegalGuardian)}
        />
        <PrivateRoute
          path={
            '/' +
            translations['cc4.seo.route.passenger'] +
            '/:passengerId/:documentTypeRouteParam'
          }
          component={DocumentSubpage(PassengerDocumentUpload)}
        />
        <PrivateRoute
          path={'/' + translations['cc4.seo.route.upload-other-documents']}
          component={DocumentSubpage(PassengerDocumentUpload)}
        />
        <PrivateRoute
          path={'/' + `${routes.claimPassengerList}`}
          component={ClaimPassengerList}
        />
      </PrivateRoute>
      <PrivateRoute
        path={
          translations['cc4.seo.route.shareable-claim-page'] +
          '/:claimNumber/' +
          translations['cc4.seo.route.passengers-list']
        }
        component={ClaimPassengerList}
      />
      <PrivateRoute path={routes.helpCenter} component={Help} />
      <PrivateRoute path={routes.myPremium} component={Premium} />
      <PrivateRoute path={routes.mySubscription} component={MySubscription} />
      <PrivateRoute path={routes.enterManual} component={EnterManualFlights} />
      <PrivateRoute
        path={translations['cc4.seo.route.help-center'] + '/:category'}
        component={HelpByQuestion}
      />
      <PrivateRoute
        path={translations['cc4.account-page.help.message-contact-us-route']}
        component={HelpSuppotMessaging}
      />
      <PrivateRoute
        path={routes.messages}
        component={SupportMessagesOverview}
      />
      <NotFoundPage default />
    </AccountRouter>
  );
};

export default ClientAccount;
